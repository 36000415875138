@import "../../../../../../assets/scss/colors";
@import "../../../../../../assets/scss/mixins";
@import "../../../../../../assets/scss/breakpoints";

.object-sections-block {
  border-bottom: 1px solid $stroke;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &._open {
    padding-bottom: 16px;
  }
}

.object-sections-block-header {
  @include header3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 16px;
  color: $dark;
  cursor: pointer;

  &::after {
    display: block;
    width: 18px;
    height: 10px;
    color: $blue;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 6' width='40' height='24' fill='%232EAADC' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 5.6252L0.625 1.2502L1.2375 0.637695L5 4.4002L8.7625 0.637695L9.375 1.2502L5 5.6252Z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: transform 250ms ease;
    content: "";

    ._open & {
      transform: rotate(180deg);
    }
  }
}