@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.modal-window-container{
  position: fixed;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-window{
  @include custom-scrollbar(10px);
  width: 76%;
  max-height: 600px;
  height: 87%;
  background-color: $white;
  border: 1px solid $grayest;
  border-radius: 5px;
  padding: 24px;
  z-index: 4;

  @include --tablet {
    width: calc(100% - 32px);
  }

  @include --mobile-lg {
    padding: 24px 20px;
  }
}

.modal-window-header{
  display: flex;
  margin-bottom: 24px;
}

.modal-window-label{
  @include header3();

  flex: 1 0 auto;
  margin-right: 12px;
}

.modal-window-close{
  cursor: pointer;

  svg{
    width: 15px;
    height: 15px;
    fill: $grayest;
  }

  &:hover{
    svg{
      fill: $dark;
    }
  }
}
