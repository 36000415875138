@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.percentage-container{
  width: 100%;
  display: flex;
  align-items: center;
}

.percentage-line{
  height: 5px;
  width: 100%;
  margin-right: 5px;
  background: $semi-gray;
  border-radius: 50px;
  display: flex;
}

.percentage-line-filler{
  height: 5px;
  background: $blue;
  border-radius: 50px;
  cursor: pointer;
}
.percentage-line-filler-unconfirmed{
  height: 5px;
  background: #f8d291;
  border-radius: 50px;
  cursor: pointer;
}

.percentage-text{
  @include text2(bold);

  color: $grayest;
}