@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.help-topic {
  @include text1;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.075);
  color: $dark;

  > *:not([class]) {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  h2:not([class]) {
    @include header2;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  h3:not([class]) {
    @include header3;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  h4:not([class]) {
    @include header4;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  p:not([class]) {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  img:not([class]) {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  ul:not([class]),
  ol:not([class]) {
    @include reset-list;
    padding-left: 16px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  figure:not([class]) {
    max-width: 840px;
	margin: auto;
    > img {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    > figcaption {
      text-align: center;
    }
  }

  ul:not([class]) {
    > li {
      position: relative;

      &::before {
        position: absolute;
        top: 7px;
        left: -16px;
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $blue;
        content: "";
      }

      ul:not([class]) > li {
        &:first-child {
          margin-top: 4px;
        }

        &::before {
          position: absolute;
          top: 9px;
          left: -16px;
          display: block;
          width: 7px;
          height: 2px;
          border-radius: 0;
          background-color: $blue;
          content: "";
        }
      }
    }
  }

  ol:not([class]) {
    list-style: none;
    counter-reset: num;

    > li {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: -16px;
        color: $blue;
        content: counter(num) ".";
        counter-increment: num;
      }
    }
  }

  a:not([class]) {
    @include reset-link;
    @include underline;
    color: $accent;
    transition: color 250ms ease, text-decoration-color 250ms ease;

    @include hover {
      color: $bluest;
    }
  }

  .accordion {
    &__toggle {
      @include header3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 16px;
      border-bottom: 1px solid $stroke;
      color: $dark;
      cursor: pointer;

      &::after {
        display: block;
        width: 18px;
        height: 10px;
        color: $blue;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 6' width='40' height='24' fill='%232EAADC' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 5.6252L0.625 1.2502L1.2375 0.637695L5 4.4002L8.7625 0.637695L9.375 1.2502L5 5.6252Z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transition: transform 250ms ease;
        content: "";
      }

      &._open {
        border: none;
        &::after {
          transform: rotate(180deg);
        }

        + .accordion__content {
          max-height: none;
          padding: 0 0 16px 16px;
          border-bottom: 1px solid $stroke;
        }
      }
    }

    &__content {
      overflow: hidden;
      max-height: 0;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
