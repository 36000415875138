@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.add-object-user {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.add-object-user-head {
  @include text1(bold);
  @include flexGap();
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  button {
    @include button-filled();

    @include --mobile-lg {
      flex: 1 0 100%;
      max-width: calc(100% - 16px);
    }
  }
}

.add-object-user-title {
  @include header3();
}

.add-object-user-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 12px;

  &:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $stroke;
  }
}

.add-object-user-name {
}

.add-object-user-number {
}

.add-object-user-mail {
}
.add-object-user-additional-info {
}

@include --tablet {
  .add-object-user-info {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@include --mobile-lg {
  .add-object-user-info {
    display: flex;
    flex-direction: column;
  }
}
