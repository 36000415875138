@import "src/assets/scss/colors";

.add-object-info-map {
  margin-top: -32px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .map-map {
    height: 368px;
    overflow: hidden;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
