@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.objects-search {
  display: grid;
  //grid-template-areas:
  //  "address name expand"
  //  "national-project job-type object-type object-status";
  grid-template-columns: repeat(12, 1fr);
  //grid-template-rows: 47px;
  grid-column-gap: 12px;
  grid-row-gap: 24px;
  margin-bottom: 24px;

  @include --mobile-lg {
    grid-row-gap: 16px;
    margin-bottom: 40px;
  }
}

.objects-search-name {
  grid-column-start: 1;
  grid-column-end: 7;

  @include --mobile-lg {
    grid-column-start: 1;
    grid-column-end: 5;
  }
}

.objects-search-address {
  grid-column-start: 7;
  grid-column-end: 13;

  @include --mobile-lg {
    grid-column-start: 5;
    grid-column-end: 9;
  }
}

.objects-search-expand {
  //grid-area: expand;
  grid-column-start: 9;
  grid-column-end: 13;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  width: 100%;
  background-color: transparent;
  border: none;
  color: $grayest;

  &:focus {
    outline: none;
  }
}

.objects-search-expand-text {
  @include header4;
}

.objects-search-expand-arrow,
.objects-search-expand-arrow-open {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 250ms;

  svg {
    height: 18px;
    width: 18px;
    fill: $grayest;
  }
}

.objects-search-expand-arrow-open {
  transform: rotate(90deg);
}

.objects-search-national-project {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 4;
}

.objects-search-job-type {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 4;
  grid-column-end: 7;
}

.objects-search-object-type {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 7;
  grid-column-end: 10;
}

.objects-search-object-status {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 10;
  grid-column-end: 13;
}

@include --mobile-lg {
  .objects-search {
    display: flex;
    flex-direction: column;
  }

  .objects-search-expand-text {
    margin-right: 12px;
  }

  .objects-search-row {
    flex-direction: column;
  }

  .objects-search-item {
    max-width: none;

    .select,
    .input-label {
      max-width: none;
      margin-bottom: 12px;
    }
  }
}

