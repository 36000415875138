@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.login-container {
  max-width: 642px;
  margin-right: auto;
  margin-left: auto;
  padding: 48px 12px 12px;
}

.login-head {
  @include header2();

  margin-bottom: 24px;
}

@media all and (max-width: 1150px) {
  .login-container {
    padding-top: 95px;
  }
}

@media all and (max-width: 430px) {
  .login-container {
    padding-top: 120px;
  }

  .login-head {
    text-align: center;
  }

  .login-logo {
    top: 65px;
    right: 0;
    left: 0;
  }

  .logo-text {
    margin-left: auto;
    margin-right: auto;
  }
}
