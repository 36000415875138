@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.sidemenu-filler {
  height: 100vh;
  min-width: auto;
  display: none;
}

.sidemenu {
  height: 100vh;
  min-width: 237px;
  background-color: $white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.08), 0 2px 24px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1000;
  transition: 250ms;
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  @include --mobile-lg {
    width: 100%;
  }
}

.sidemenu-close {
  min-width: 40px;
}

.sidemenu-toggle {
  @include reset-btn;
  @include button-text;
  align-self: center;
  color: $dark;

  &:hover {
    color: $accent;
  }

  &:active {
    color: $bluest;
  }

  > svg {
    width: 24px;
    height: 24px;
    margin: 0;
  }
}

.sidemenu-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.sidemenu-control {
  width: 40px;
  height: 24px;
  background: transparent;
  border: none;
  position: absolute;
  top: 24px;
  left: 0;
  display: flex;
  padding: 12px 0 12px 12px;
  align-items: center;
  cursor: pointer;
}

.sidemenu-control:focus {
  outline: none;
}

.sidemenu-close .sidemenu-logo {
  display: none;
}

.sidemenu-logo-text {
  @include logo();

  color: $dark;
}

.sidemenu-tabs {
  list-style: none;
  margin-bottom: 0;

  > li {
    position: relative;
  }
}

.sidemenu-tabs li {
  position: relative;
}

.sidemenu-tabs-tab {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  border-right: 4px solid transparent;
  text-decoration: none;
  color: $dark;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  &:hover {
    color: $accent;
    background-color: $blue-background-light;
  }
}

.sidemenu-tabs-tab-active {
  cursor: default;
  color: $accent;
  border-right-color: $accent;
  background-color: $blue-background-light;
}

.notification-count {
  cursor: pointer;
  color: $white;
  font-size: 10px;
  line-height: 10px;
  background-color: $red;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  //position: absolute;
  border-radius: 10px;
  //top: -1px;
  //left: 33px;

  &:not(:first-child) {
    margin-left: 12px;
  }
}

.sidemenu:not(.sidemenu-close) {
  .notification-count {
    left: 33px;
  }
}

.sidemenu-close {
  .notification-count {
    left: 24px;
  }
}

.sidemenu-close .sidemenu-tabs-tab {
  padding-left: 0;
  display: flex;
  justify-content: center;
}

.sidemenu-tabs-tab:hover {
  text-decoration: none;
}

.sidemenu-tabs-tab-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 8px;
  }

  > svg {
    width: 24px;
    height: 24px;
  }
}

.sidemenu-tabs-tab-text,
.sidemenu-link__label {
  @include text1();
}

.sidemenu-close {
  .sidemenu-tabs-tab-text {
    display: none;
    padding-right: 0;
  }
}

.sidemenu-link {
  display: flex;
  align-items: flex-end;
  padding-left: 16px;

  &__label {
    @include underline;
    cursor: pointer;
    transition: color 250ms ease, text-decoration-color 250ms ease;

    @include hover {
      color: $accent;
    }
  }
}

.sidemenu-user-block {
  display: flex;
  align-items: flex-start;
  margin-top: auto;
  padding: 0 16px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.sidemenu-user-info {
}

.sidemenu-user-role,
.sidemenu-user-name {
  display: block;
}

.sidemenu-user-role {
  @include text2;
  max-width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $grayest;
}

.sidemenu-user-name {
  @include reset-link;
  @include text1;
  max-width: 85%;
  color: $accent;
}

.sidemenu-user-logout {
  @include button-text;
  margin-left: auto;
  color: inherit;

  > svg {
    width: 24px;
    height: 24px;
    fill: none;
  }
}

@include --tablet {
  .sidemenu-filler {
    display: block;
  }

  .sidemenu {
    position: absolute;
  }
}
