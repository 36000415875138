@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.notifications-wrapper {
  > .container {
    max-width: 728px;
  }
}

.notifications-header{
  @include header1();

  color: $dark;
  margin-bottom: 24px;
}

.notifications-cards-head{
  @include text1();

  color: $grayest;
  display: grid;
  grid-template-areas: "task text time read read";
  grid-template-columns: 1fr 1fr 1fr 100px 100px;
  grid-column-gap: 24px;
  padding: 0 24px;
  margin-bottom: 12px;
  list-style: none;
}

.notifications-cards-header-task{
  grid-area: task;
}

.notifications-cards-header-text{
  grid-area: text;
}

.notifications-cards-header-time{
  grid-area: time;
}

.notifications-cards-header-read{
  grid-area: read;

  button{
    @include button-filled();
  }
}

.notifications-cards-read-all {
  @include button-filled;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.notifications-cards-list {
  @include reset-list;
  margin: 0 -32px;
  padding: 0;

  @include --tablet {
    margin: 0 -24px;
  }

  @include --mobile-lg {
    margin: 0 -20px;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
