@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.solo-object-map {
  margin-top: -32px;
  border-radius: 8px;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
  
  .map-map {
    height: 368px;
    min-height: 220px;
  }
}
