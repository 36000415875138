@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.stage-histories {
  list-style: none;
}

.stage-histories-card-open,
.stage-histories-card-closed {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.stage-histories-card-open {
  .stage-histories-info {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.stage-histories-info {
  display: grid;
  grid-column-gap: 12px;
  grid-template-areas: "amount status created updated last";
  grid-template-columns: 112px 156px minmax(112px, 156px) minmax(112px, 156px) minmax(52px, 1fr);
  align-items: center;
  padding: 16px 24px;
  transition: background-color 250ms ease;

  @include hover {
    background-color: $blue-background-light;
  }

  @include --tablet {
    grid-template-columns: 112px 130px 1.6fr 1.6fr minmax(52px, 1fr);
  }

  @include --mobile-lg {
    display: flex;
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.stage-histories-label {
  @include text1;
  color: $grayest;
}

.stage-histories-status,
.stage-histories-created,
.stage-histories-updated,
.stage-histories-amount,
.stage-histories-unit {
  @include --mobile-lg {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-column-gap: 16px;
    width: 100%;
  }
}

.stage-histories-status {
  @include text1();
  grid-area: status;
  justify-self: start;
}

.stage-histories-created {
  grid-area: created;
}

.stage-histories-updated {
  grid-area: updated;
}

.stage-histories-table-controls {
  grid-area: last;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > * {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.stage-histories-delete,
.stage-histories-view {
  @include button-text();

  @include --mobile-lg {
    @include button-outline;
  }
}

.stage-histories-controls-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include --mobile-lg {
    > * {
      flex: 1 0 100%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.stage-histories-controls {
  @include flexGap();
}

.stage-histories-controls-approve,
.stage-histories-controls-decline {
  @include button-outline();
  min-width: 240px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.stage-histories-controls-edit {
  @include button-filled();

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.stage-histories-controls-delete {
  @include button-outline();
}

.stage-histories-amount {
  grid-area: amount;
}

.stage-histories-unit {
  grid-area: last;
}
