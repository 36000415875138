@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.add-history {
  display: flex;
  align-items: center;
}

.add-history-head {
  @include flexGap();
  justify-content: space-between;

  @include --mobile-lg {
    > * {
      flex: 1 0 auto;
    }
  }
}

.add-history-info {
  display: flex;
  align-items: center;

  @include --mobile-lg {
    flex-direction: column;

    > * {
      width: 100%;
      max-width: 100%;
    }
  }

  label {
    max-width: 220px;

    @include --mobile-lg {
      max-width: 100%;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.add-history-info-label {
  @include text1();
  flex-shrink: 0;
  color: $grayest;

  &:not(:last-child) {
    margin-right: 16px;

    @include --mobile-lg {
      margin-right: 0;
      margin-bottom: 4px;
    }
  }
}

.add-history-info-unit {
  @include text1();
}

.add-history-info-controls {
  display: flex;

  > * {
    @include --mobile-lg {
      flex: 1 0 auto;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.add-history-save {
  @include button-filled();
  margin-left: auto;
}

.add-history-cancel {
  @include button-outline();
}

.add-history-create {
  @include button-filled();
}
