.file-list-upload {
  cursor: pointer;
  position: relative;
}

.file-list-upload-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}
