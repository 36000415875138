@import "../../assets/scss/mixins";
@import "../../assets/scss/colors";
@import "../../assets/scss/breakpoints";

.user-info-editable {
  &__header {
    @include flexGap(16px);
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @include --mobile-lg {
      padding-top: 24px;
    }
  }

  &__title {
    @include header3();

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__edit-btn {
    @include reset-btn;
    @include button-outline;

    @include --mobile-lg {
      @include button-text;
    }
  }
}