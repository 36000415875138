@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.object-section {
  width: 100%;
  padding-left: 12px;
  border-left: 1px solid $stroke;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.object-section-content {
  padding-left: 30px;

  @include --mobile-lg {
    padding-left: 0;
  }
}

.object-section-label,
.object-section-label-open {
  display: flex;
  cursor: pointer;

  > * {
    //flex: 1 0 auto;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.indicator {
  width: 9px;
  height: 9px;
  min-width: 9px;
  min-height: 9px;
  border-radius: 48%;
  background-color: $red;
  margin-left: 8px;
}

.object-section-label-open {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.object-section-label-text {
  @include header4();
  display: flex;
  align-items: center;
}

.object-section-label-arrow,
.object-section-label-arrow-open {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 250ms;
  height: 12px;
  width: 12px;
  min-height: 18px;
  min-width: 18px;

  svg {
    width: 12px;
    height: 7px;
    fill: $blue;
  }
}

.object-section-label-arrow-open {
  transform: rotate(180deg);
}

.object-section-filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  > * {
    @include --mobile-lg {
      flex: 1 0 auto;
    }

    &:not(:last-child) {
      margin-right: 12px;

      @include --mobile-lg {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}

.object-section-search {
  flex: 1 0 auto;
}

.object-section-list {
  border: 1px solid $stroke;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 24px;

    @include --mobile-lg {
      margin-bottom: 16px;
    }
  }

  @include --mobile-lg {
    margin: 0 -20px 0 -12px;
    border: none;
  }
}

.object-section-stages {
  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }

  @include --mobile-lg {
    border-bottom: 1px solid $stroke;

    &:first-of-type {
      border-top: 1px solid $stroke;
    }
  }
}

.object-section-head {
  @include header4();

  display: grid;
  align-items: center;
  //grid-template-areas: "name status begin end amount unit completion empty";
  grid-template-areas: "name status begin end amount completion empty";
  grid-template-columns: 2.4fr 1.2fr 0.8fr 0.8fr 0.8fr 0.8fr 52px;
  grid-column-gap: 12px;
  padding: 16px 24px;
  list-style: none;
  color: $accent;
  text-decoration: none;
  background-color: $gray-background;

  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }
}

.object-section-head-name {
  grid-area: name;
  a {
    @include reset-link;
    color: $accent;
  }
}

.object-section-head-begin {
  grid-area: begin;
}

.object-section-head-end {
  grid-area: end;
}

.object-section-head-amount {
  grid-area: amount;
}

.object-section-head-unit {
  grid-area: unit;
}

.object-section-head-completion {
  grid-area: completion;
}

.object-section-head-status {
  grid-area: status;
  a {
    @include reset-link;
    color: $accent;
  }
}

.edit-section {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  > * {
    @include --mobile-lg {
      flex: 1 0 auto;
    }

    &:not(:last-child) {
      margin-right: 24px;

      @include --mobile-lg {
        margin-right: 12px;
      }
    }
  }

  .edit,
  .delete {
    @include button-text();

    > svg {
      margin: 0 10px 0 0;
    }
  }
}

.object-section-paginator {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.object-section-paginator-total {
  @include --mobile-lg {
    align-self: flex-end;
    margin-bottom: 8px;
  }
}

.show-all,
.hide-all {
  @include button-outline;

  @include --mobile-lg {
    flex: 1 0 100%;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

@include --tablet {
  .object-section-head {
    display: none;
  }
  .object-section-label,
  .object-section-label-open {
    height: unset;
  }
}

@include --mobile-lg{
  .edit-section {
    width: 100%;
    //.delete,
    //.edit {
    //  width: 100%;
    //  margin-bottom: 12px;
    //}
  }
  .object-section-paginator {
    flex-direction: column;
    padding-right: 0;
    .show-all {
      margin-top: 12px;
    }
  }
}
