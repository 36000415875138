@import "colors";
@import "breakpoints";

@mixin header1($weight: normal) {
  font-family: "PT Astra Sans", sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: 56px;
  line-height: 64px;

  @include --mobile-lg {
    font-size: 36px;
    line-height: 44px;
  }
}

@mixin header2($weight: bold) {
  font-family: "PT Astra Sans", sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: 40px;
  line-height: 48px;

  @include --mobile-lg {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin header3($weight: bold) {
  font-family: "PT Astra Sans", sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: 24px;
  line-height: 32px;

  @include --mobile-lg {
    font-size: 18px;
    line-height: 26px;
  }
}

@mixin header4($weight: bold) {
  font-family: "PT Astra Sans", sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: 18px;
  line-height: 24px;

  @include --mobile-lg {
    font-size: 16px;
    line-height: 22px;
  }
}

@mixin text1($weight: normal) {
  font-family: "PT Astra Sans", sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: 16px;
  line-height: 20px;

  @include --mobile-lg {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin text2($weight: normal) {
  font-family: "PT Astra Sans", sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: 14px;
  line-height: 18px;

  @include --mobile-lg {
    font-size: 12px;
    line-height: 16px;
  }
}

@mixin text3($weight: normal) {
  font-family: "PT Astra Sans", sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: 12px;
  line-height: 16px;
}

@mixin logo() {
  font-family: "PT Astra Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  max-width: 150px;
}

@mixin button() {
  @include reset-btn;
  @include text1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  &._small {
    padding: 6px 12px;
  }

  &._icon {
    padding: 0;

    > svg {
      &:only-child {
        margin: 0;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  > svg {
    width: 20px;
    height: 20px;
    margin: 0 -8px;
    transition: fill 250ms ease, stroke 250ms ease;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  @include hover {
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    //color: $grayest !important;
  }

  &:focus {
    outline: none;
  }
}

@mixin button-filled() {
  @include button;
  background: $blue;
  border-color: rgba(0, 0, 0, 0.2);
  color: $white;

  &:disabled {
    background-color: $grayest;
  }

  &:not(:disabled) {
    @include hover {
      background-color: $bluer;
    }

    @include active {
      background-color: $bluest;
    }

    &._error {
      background-color: $red;

      @include hover {
        background-color: $redder;
      }

      @include active {
        background-color: $reddest;
      }
    }

    &._success {
      background-color: $green;

      @include hover {
        background-color: $greener;
      }

      @include active {
        background-color: $greenest;
      }
    }

    &._warn {
      background-color: $yellow;

      @include hover {
        background-color: $yellower;
      }

      @include active {
        background-color: $yellowest;
      }
    }
  }
}

@mixin button-outline() {
  @include button;
  background: $white;
  border-color: $blue;
  color: $blue;

  &:disabled {
    color: $grayest;
    border-color: $grayest;
    background-color: $white;
  }

  &:not(:disabled) {
    @include hover {
      border-color: $bluer;
      color: $bluer;
      background-color: $blue-background-light;
    }

    @include active {
      border-color: $bluest;
      color: $bluest;
      background-color: $blue-background;
    }

    &._error {
      border-color: $red;
      color: $red;

      @include hover {
        &:not(:disabled) {
          border-color: $redder;
          color: $redder;
          background-color: $red-background-light;
        }
      }

      @include active {
        &:not(:disabled) {
          border-color: $reddest;
          color: $reddest;
          background-color: $red-background;
        }
      }
    }

    &._success {
      border-color: $green;
      color: $green;

      @include hover {
        &:not(:disabled) {
          border-color: $greener;
          color: $greener;
          background-color: $green-background-light;
        }
      }

      @include active {
        &:not(:disabled) {
          border-color: $greenest;
          color: $greenest;
          background-color: $green-background;
        }
      }
    }

    &._warn {
      border-color: $yellow;
      color: $yellow;

      @include hover {
        &:not(:disabled) {
          border-color: $yellower;
          color: $yellower;
          background-color: $yellow-background-light;
        }
      }

      @include active {
        &:not(:disabled) {
          border-color: $yellowest;
          color: $yellowest;
          background-color: $yellow-background;
        }
      }
    }
  }
}

@mixin button-text() {
  @include button;
  padding: 0;
  border: none;
  color: $blue;
  background-color: transparent;

  &:disabled {
    color: $grayest;
  }

  &:not(:disabled) {
    @include hover {
      border: none;
      color: $bluer;
      background-color: transparent;
    }

    @include active {
      border: none;
      color: $bluest;
      background-color: transparent;
    }

    &._error {
      color: $red;

      @include hover {
        color: $redder;
      }

      @include active {
        color: $reddest;
      }
    }

    &._success {
      color: $green;

      @include hover {
        color: $greener;
      }

      @include active {
        color: $greenest;
      }
    }

    &._warn {
      color: $yellow;

      @include hover {
        color: $yellower;
      }

      @include active {
        color: $yellowest;
      }
    }
  }
}

@mixin reset-btn {
  padding: 0;
  border: none;
  background-color: transparent;
}

@mixin reset-link {
  color: inherit;
  text-decoration: none;
}

@mixin reset-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

$scrollbarWidth: 10px;

@mixin custom-scrollbar($paddingRight: 0) {
  overflow-x: hidden;
  overflow-y: auto;

  @include --from-tablet {
    padding-right: $paddingRight + $scrollbarWidth;

    &::-webkit-scrollbar {
      width: $scrollbarWidth; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      border-radius: 100px;
      background: rgba($grayest, 0.3); /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px; /* roundness of the scroll thumb */
      background-color: rgba($grayest, 0.7); /* color of the scroll thumb */
    }
  }
}

$width_container: 1440px;
$width_container_tablet: calc(100% - 32px);
$width_container_mobile: calc(100% - 32px);

@mixin container {
  width: 100%;
  max-width: $width_container;

  @media (max-width: 1360px) {
    max-width: 100%;
  }
}

// hover only from tablet
@mixin hover {
  @media (min-width: $from_tablet) {
    &:hover {
      @content;
    }
  }
}

// active only if element is hover and from tablet
@mixin active {
  @media (min-width: $from_tablet) {
    &:hover:active {
      @content;
    }
  }
}

@mixin flexGap($gap: 16px, $width: 100%) {
  display: inline-flex;
  flex-flow: row wrap;
  width: calc(#{$width} + #{$gap});
  margin: calc(-1 * #{$gap}) 0 0 calc(-1 * #{$gap});

  > * {
    margin: $gap 0 0 $gap;
  }
}

@mixin underline() {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 1px;
  text-decoration-color: transparent;
  transition: text-decoration-color 250ms ease;

  @include hover {
    text-decoration-color: inherit;
  }
}
