@import "../../../../../../../../assets/scss/colors";
@import "../../../../../../../../assets/scss/mixins";

.add-object-sections-list {
  @include reset-list;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.add-object-sections-list-item {
  padding-left: 12px;
  border-left: 1px solid $stroke;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
