@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.overall-rating {
  > .tabs {
    max-width: 557px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.rating-table {
  @include text1;
  border: 1px solid $stroke;
  border-radius: 8px;

  @include --mobile-lg {
    border: none;
  }

  &:not(:last-child) {
    margin-bottom: 24px;

    @include --mobile-lg {
      margin-bottom: 16px;
    }
  }

  &__head,
  &__row {
    display: grid;
    padding: 16px 24px;
    grid-template-columns: 1.5fr 1.2fr 1fr 1.2fr;
    grid-column-gap: 24px;
    justify-items: start;

    &:not(:last-child) {
      border-bottom: 1px solid $stroke;
    }

    @include --tablet {
      grid-column-gap: 12px;
    }

    @include --mobile-lg {
      margin: 0 -20px;
      padding: 12px 20px;
      border-bottom: 1px solid $stroke;
    }
  }

  &__head {
    @include header4();
    color: $accent;
    background-color: $gray-background;

    @include --mobile-lg {
      grid-template-columns: min-content min-content;
      grid-row-gap: 12px;
      background-color: transparent;
      padding-top: 0;
    }
  }

  &__row {
    @include --mobile-lg {
      grid-template-columns: 1fr;
      grid-row-gap: 16px;
    }

    a {
      @include reset-link;
      @include underline;
      transition: color 250ms ease, text-decoration-color 250ms ease;

      @include hover {
        color: $accent;
      }
    }
  }

  &__cell {
    @include --mobile-lg {
      display: grid;
      grid-template-columns: 1fr 2.4fr;
      grid-column-gap: 12px;
      width: 100%;
    }
  }

  &__sort-label {
    @include text1();
    grid-column: 1 / 3;
    color: $dark;
  }

  &__errors {
    color: $red;
  }

  .sort {
    display: inline-flex;
    cursor: pointer;

    > svg {
      &:not(:first-child) {
        margin-left: 10px;
      }

      path {
        stroke: $grayest;
        transition: stroke 250ms ease;

        ._asc & {
          stroke: $blue;
        }
      }
    }

    &._desc,
    &._asc {
      > svg {
        path {
          stroke: $blue;
        }
      }
    }

    @include hover {
      > svg {
        path {
          stroke: currentColor;
        }
      }
    }
  }

  .paginator {
    justify-self: center;

    &:not(:first-child) {
      margin-top: 48px;
    }
  }
}
