@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.multiselect {
  position: relative;
  width: 100%;
  min-width: 180px;

  * {
    box-sizing: border-box;
  }

  &__toggle {
    @include reset-btn;
    @include text2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 6px 12px;
    border: 1px solid $grayer;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 250ms ease;

    > svg {
      &:not(:first-child) {
        margin-left: 12px;
      }
    }

    &:hover {
      border-color: $grayest;
    }

    &:focus {
      border-color: $blue;
    }
  }

  &__list {
    @include reset-list;
    position: absolute;
    z-index: 100;
    width: 100%;
    max-width: 100%;
    padding: 6px;
    border: 1px solid $grayer;
    border-radius: 4px;
    background-color: $white;

    > li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &__option {
    display: inline-flex;
    cursor: pointer;
    width: 100%;
    max-width: 100%;

    > input {
      &:not(:last-child) {
        margin: 2px 6px 0 0;
      }
    }

    > span {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}