@import "../../assets/scss/colors";
@import "../../assets/scss/mixins";
@import "../../assets/scss/breakpoints";

.start-page {
  box-sizing: border-box;

  &.container {
    @include --from-mobile-lg {
      margin-top: -16px;
    }
  }

  .info-image {
    display: flex;
    margin: 0 -40px -64px 0;
    align-items: flex-end;
    justify-content: flex-end;

    > img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }

    @include --tablet {
      margin: 0 -24px -40px 0;
    }

    @include --mobile-lg {
      margin: 0 -24px -40px;
    }
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 64px 40px;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
      0px 2px 24px rgba(0, 0, 0, 0.08);
    border-radius: 20px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    > * {
      flex: 1 0 50%;
      max-width: 50%;

      @include --mobile-lg {
        flex: 1 0 100%;
        max-width: 100%;
      }
    }

    > .info-image {
      @include --mobile-lg {
        flex: 1 0 100%;
        width: calc(100% + 24px * 2);
        max-width: calc(100% + 24px * 2);
      }
    }

    @include --tablet {
      padding: 40px 24px;
    }

    &-text {
      &__sub-header {
        @include text1();
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #333333;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      &__header {
        @include text1(bold);
        font-size: 74px;
        line-height: 85px;
        color: #184870;

        &:not(:last-child) {
          margin-bottom: 29px;
        }

        @media all and (max-width: 767px) {
          font-size: 36px;
          line-height: 42px;

          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }
      &__list {
        @include --mobile-lg {
          max-width: none;
        }

        .description {
          @include text1();
          font-size: 20px;
          line-height: 24px;
          color: #333333;

          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
        pwa-install::part(openButton) {
          background: #2eaadc;
          border-radius: 80px;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          padding: 14px 32px;
        }

        .add-to-home-screen {
          @include button-filled();
          width: 90%;
          margin-top: 12px;
          height: 35px;
        }
        span {
          @include text1(bold);
          font-size: 18px;
        }
        ul {
          margin-left: 24px;
          margin-top: 18px;
          li {
            font-size: 18px;

            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
    .main-image {
      width: fit-content;
      img {
        max-width: 600px;
      }
    }
  }
  .content {
    display: flex;
    padding-bottom: 30px;

    @include --mobile-lg {
      flex-wrap: wrap;
    }

    > * {
      width: 50%;
      padding: 40px;
      background: #ffffff;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
        0px 2px 24px rgba(0, 0, 0, 0.08);
      border-radius: 20px;

      @include --tablet {
        padding: 24px;
      }

      @include --mobile-lg {
        width: auto;
      }
    }
    .left {
      margin-right: 8px;

      @media all and (max-width: 767px) {
        margin-right: 0;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      .name {
        font-family: "PT Astra Sans", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        line-height: 51px;
        color: #184870;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        @media all and (max-width: 767px) {
          font-size: 30px;
          line-height: 38px;
        }
      }
      ul {
        padding-left: 20px;

        li {
          color: #2eaadc;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }

          span {
            color: #333333;
            font-size: 18px;
          }
        }
      }
    }
    .right {
      font-family: "PT Astra Sans", sans-serif;
      margin-left: 8px;
      font-size: 18px;
      line-height: 24px;
      color: #8d8d8d;

      @media all and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
}
