@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.object-info {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas:
    "address address address address address ref ref ref ref status status status"
    "description description description description description description description description description description description description"
    "national-project national-project national-project national-project job-type job-type job-type job-type object-type object-type object-type object-type"
    "plan-beg plan-beg plan-beg plan-beg plan-end plan-end plan-end plan-end controls controls controls controls";
  grid-row-gap: 16px;
  grid-column-gap: 12px;

  @include --mobile-lg {
    grid-template-columns: 1fr;

    grid-template-areas:
      "national-project"
      "status"
      "address"
      "job-type"
      "object-type"
      "ref"
      "plan-beg"
      "plan-end"
      "description";
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.object-info-address {
  grid-area: address;
}

.object-info-status {
  grid-area: status;
  justify-self: end;

  @include --mobile-lg {
    justify-self: start;
  }
}

.object-info-status-label {
  @include text1();
  color: $grayest;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.object-info-status-comment {
  @include text1;
}

.object-info-description {
  grid-area: description;
}

.object-info-ref {
  grid-area: ref;
}

.object-info-national-project {
  grid-area: national-project;
}

.object-info-job-type {
  grid-area: job-type;
}

.object-info-object-type {
  grid-area: object-type;
}

.object-info-plan-beg {
  grid-area: plan-beg;
}

.object-info-plan-end {
  grid-area: plan-end;
}

.solo-object-controls {
  grid-area: controls;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  > * {
    flex: 1 0 auto;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.solo-object-controls-cancel {
  @include button-outline();
}

.solo-object-controls-save {
  @include button-filled();
}
