@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.stage-container {
  max-width: 1112px;
  margin-right: auto;
  margin-left: auto;
  padding: 12px;
}

.stage-card {
  border: 1px solid $grayer;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.stage-histories-table {
  border: 1px solid $stroke;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  @include --mobile-lg {
    border: none;
  }
}

.stage-card-header {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.stage-card-info {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.stage-card-comments {
  border-top: 1px solid $grayer;
  padding-top: 24px;
}

.stage-histories-add {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.stage-histories-head {
  @include header3();

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.stage-histories-header {
  @include reset-list;
  @include header4();

  color: $accent;
  display: grid;
  align-items: center;
  grid-column-gap: 12px;
  grid-template-areas: "amount status created updated last";
  grid-template-columns: 112px 156px minmax(112px, 156px) minmax(112px, 156px) minmax(52px, 1fr);
  padding: 16px 24px;
  background-color: $gray-background;

  @include --tablet {
    grid-template-columns: 112px 130px 1.6fr 1.6fr minmax(52px, 1fr);
  }

  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }
}

.stage-histories-header-status {
  grid-area: status;
}

.stage-histories-header-created {
  grid-area: created;
}

.stage-histories-header-updated {
  grid-area: updated;
}

.stage-histories-header-amount {
  grid-area: amount;
}

.stage-histories-header-unit {
  grid-area: last;
}

.stage-histories-container {
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.stage-histories-item {
  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }

  @include --mobile-lg {
    margin: 0 -20px;
    border-bottom: 1px solid $stroke;

    &:first-child {
      border-top: 1px solid $stroke;
    }
  }
}
