@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.input-label {
  @include text1();
  display: block;
  width: 100%;
  margin-bottom: 0;
  color: $grayest;
}

.input-label-container {
  position: relative;
  display: flex;
  justify-content: space-between;

  > svg {
    position: absolute;
    right: 12px;
    bottom: 10px;
    width: 20px;
    height: 20px;
  }
}

.input-label-text {
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:empty {
    display: none;
  }
}

.input-label-text-error {
  color: $red;

  &:not(:first-child) {
    margin-top: 4px;
  }
}

.input-input {
  @include text1();
  color: $dark;
  width: 100%;
  padding: 9px 12px;
  border: 1px solid $grayer;
  border-radius: 4px;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  ._with-icon {
    padding-right: 44px;
  }
}

.input-input:focus {
  border: 1px solid $blue;
  background-color: $white;
  outline: 0;
}

.input-input-error {
  border-color: $red;
  background-color: $red-background-light;
}
