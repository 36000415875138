@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.modal-container {
  &__hidden {
    display: none;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 100%;

  &__cancel {
    appearance: none;
    background: none;
    padding: 0;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: #787878;
    top: 24px;
    right: 24px;
    cursor: pointer;
    z-index: 1;
  }

  &__background {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.764);
  }

  &__content-container {
    position: fixed;
    top: 50%;
    left: 35%;
    z-index: 11;
  }

  &__content {
    padding: 56px 56px 32px;
    &__props {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }

    &__button-container {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
    }
  }

  @media (max-width: 1025px) {
    &__content-container {
      left: 30%;
    }
  }

  @media (max-width: 769px) {
    &__content-container {
      left: 22%;
    }
  }

  @media (max-width: 426px) {
    &__content-container {
      height: 100%;
      top: 0;
      left: 0;
      width: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 12px;
    }
  }
}
.button-ok {
  @include button-filled();
  width: 100px;
}

.button-cancel {
  @include button-outline();
  width: 100px;
  border-color: $red;
  color: $red;

  &:hover {
    background-color: $red;
  }
}

@media (max-width: 426px) {
  .button-ok,
  .button-cancel {
    width: 150px;
  }
}

@media (max-width: 365px) {
  .button-ok,
  .button-cancel {
    width: 100px;
  }
}
