@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.help-topics {
  &-container {
    max-width: 1112px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 12px;
    padding-left: 12px;
  }

  &-header {
    margin-bottom: 50px;
  }

  &-title {
    @include header1();
    margin-bottom: 0;
    color: $dark;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    gap: 24px;
  }

  &-topic {
    display: block;
    border: 1px solid #dbdad6;
    border-radius: 6px;
    padding: 24px;
    text-decoration: none;

    &-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: black;
    }
  }
}
