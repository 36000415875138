@import "src/assets/scss/mixins";

.main-layout {
  display: flex;
  background-color: #F5F5F5;
}

.main-layout-content {
  @include custom-scrollbar(-10px);
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
