@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.objects-list-container {
  height: 100%;
  margin-bottom: 24px;
  position: relative;
}

.not-confirmed {
  margin-bottom: 16px;
  padding: 0 32px;
  font-size: 32px;
  line-height: 40px;
}

.not-confirmed-text {
  padding: 0 32px;
  font-size: 16px;
  line-height: 24px;
}
