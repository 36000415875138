@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.add-object-header-wrapper {
  position: relative;
  z-index: 1;
}

.add-object-header {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-areas:
    "name tabs"
    "info info";
  grid-gap: 16px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.add-object-header-name {
  grid-area: name;
  align-self: center;

  .input-input {
    @include text1(bold);
  }
}

.add-object-header-tabs {
  grid-area: tabs;
  align-self: center;
}

@include --mobile-lg {
  .add-object-header {
    display: flex;
    flex-direction: column;
    .add-object-header-name {
      width: 100%;
      margin-bottom: 12px;
    }
    .add-object-header-tabs {
      width: 100%;
      .tabs {
        width: unset !important;
      }
    }
  }
}
