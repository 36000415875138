@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.objects-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @include --mobile-lg {
    margin-bottom: 16px;
  }
}

.objects-header-title {
  @include header1;
  flex: 1 1 100%;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.objects-header-text {
  @include header2();

  &:empty {
    display: none;
  }

  margin-bottom: 0;
  color: $dark;
}

.objects-header-available {
  @include text1();
  font-weight: bold;
  cursor: pointer;
  color: $grayest;

  &:empty {
    display: none;
  }

  &:hover {
    color: black;
  }
}

.objects-header-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 0 0 100%;
  border-bottom: 1px solid $stroke;

  &._without-divider {
    border-bottom: none;
  }

  .objects-header-title {
    flex-basis: auto;
  }

  @include --mobile-lg {
    border-bottom: none;

    > * {
      flex-basis: 100%;
    }
  }

  &:not(:last-child) {
    margin-bottom: 32px;

    @include --mobile-lg {
      margin-bottom: 16px;
    }
  }

  > *:not(:last-child) {
    margin-right: 24px;

    @include --mobile-lg {
      margin: 0 0 16px;
    }
  }
}

.objects-header-controls-switch {
  margin-right: 12px;
  width: 100%;
  min-width: 300px;
  max-width: 300px;
}

.objects-header-controls-button,
.objects-header-controls-button__disabled {
  @include reset-link;
  @include button-filled();

  @include --mobile-lg {
    width: 100%;
  }

  &__disabled {
    border-color: $grayest;
    background-color: $grayer;
    pointer-events: none;
  }
}

.objects-header-controls-button-mobile {
  @include button-filled();
  display: none;
}
