@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.state-container {
  @include text1(bold);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 6px 12px 6px 32px;
  color: $grayest;
  background-color: $grayest_background-light;

  @include --tablet {
    padding: 4px 10px;
  }

  @include --mobile-lg {
    display: inline-block;
    padding: 0;
    background-color: transparent;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 12px;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: currentColor;
    transform: translateY(-50%);
    content: "";

    @include --tablet {
      content: none;
    }
  }
}

.state-plain {
  background: $semi-gray;
}

.state-process {
  background: $yellow;
}

.state-ended {
  background: $green;
}

.state-unstarted {
  background: $red;
}
