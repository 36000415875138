@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.rights {
  &-header {
    @include header1();

    color: $dark;
    margin-bottom: 24px;
  }
  &-content {
    &-table {
      border: 1px solid $stroke;
      border-radius: 8px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      @include --mobile-lg {
        margin: 0 -20px;
        border: none;
        border-top: 1px solid $stroke;
        border-bottom: 1px solid $stroke;
        border-radius: 0;
      }
    }

    &-head {
      @include header4();

      color: $accent;
      display: grid;
      grid-template-columns: 1.2fr 0.8fr 1fr 1.2fr 2fr 80px 60px;
      grid-column-gap: 12px;
      grid-auto-columns: min-content;
      padding: 16px;
      list-style: none;
      background-color: $gray-background;

      &:not(:last-child) {
        border-bottom: 1px solid $stroke;
      }

      &-rights {
        text-align: center;
      }
    }
  }
}
