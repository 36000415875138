@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.input-field {
  &:last-of-type {
    padding-right: 0;
  }
}

.input-field-container {
  display: flex;
  flex-direction: column;

  @include --mobile-lg {
    flex-direction: column-reverse;
  }
}

.input-field-label {
  @include text1();
  color: $grayest;

  @include --mobile-lg {
    margin-bottom: 4px;
  }
}

.input-field-text {
  @include text1();
  color: $dark;

  &._title {
    @include header4();
  }

  @include --from-tablet {
    margin-bottom: 4px;
  }
}

.object-info-filler {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
