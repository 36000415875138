@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.input-date-container {
  width: 100%;
  position: relative;
}

.input-date {
  width: 100%;
}

.input-date-info {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.input-date-label,
.input-date-error {
  @include text1();
  color: $grayest;
}

.input-date-error {
  color: $red;
}

.input-date-input,
.input-date-input-error {
  @include text1();

  color: $dark;
  width: 100%;
  border: 1px solid $grayer;
  border-radius: 4px;
  padding: 9px 12px;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  &:focus {
    outline: none;
    border-color: $blue;
  }
}

.input-date-input-error {
  border-color: $red;
}

.input-date-icon,
.input-date-icon-open {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: fill 250ms ease;

  @include --mobile-lg {
    bottom: 8px;
  }

  svg {
    fill: $grayest;
  }
}

.input-date-icon {
  @include hover {
    svg {
      fill: $blue;
    }
  }
}

.input-date-icon-open {
  svg {
    fill: $red;
  }
}

.input-date-picker-container {
  left: 0;
  top: calc(100% + 2px);
  width: 100%;
  position: absolute;
}
