@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.object-stage {
}

.object-stage-head{
  display: grid;
  grid-template-areas: "select name icon";
  grid-template-columns: 30px 1fr 30px;
  grid-column-gap: 12px;
  cursor: pointer;
  padding: 12px 6px;
  align-items: center;
  background-color: $gray;

  &:hover{
    background-color: $grayer;

    svg{
      fill: $dark;
    }
  }
}

.object-stage-select{
  grid-area: select;
  justify-self: center;
  height: 14px;

  input{
    cursor: pointer;
  }
}

.object-stage-name{
  @include text1(bold);

  color: $dark;
  grid-area: name;
}

.object-stage-icon,
.object-stage-icon-open{
  grid-area: icon;

  svg{
    width: 18px;
    height: 10px;
    fill: $grayest;
  }
}

.object-stage-icon-open{
  svg{
    transform: rotate(90deg);
  }
}

.object-stage-body{
  .paginator {
    justify-content: center;
    margin: 12px 0;
  }
}

.object-stage-item{
  display: grid;
  grid-template-areas: "select name";
  grid-template-columns: 30px 1fr;
  grid-column-gap: 12px;
  align-items: center;
  padding: 12px 6px;
  cursor: pointer;
}

.object-stage-item-select{
  grid-area: select;
  justify-self: center;
  height: 14px;
}

.object-stage-item-name{
  @include text1();

  color: $dark;
  grid-area: name;
}