.add-object-stages-container {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
  min-height: 710px;
}

@media all and (max-width: 980px) {
  .add-object-stages-container {
    min-height: unset !important;
  }
}
