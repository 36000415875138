@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.registration-form {
  border: 0.5px solid $grayer;
  border-radius: 3px;
  padding: 48px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.075);
  display: flex;
  flex-direction: column;
  background: #fff;
}

.registration-label {
  width: 100%;
  max-width: 263px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.registration-form-reset {
  color: $dark;
  display: block;
  width: 100%;
  max-width: 263px;
  margin: 0 auto 12px;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-decoration: none;

  &:hover {
    opacity: 0.85;
  }
}

.registration-label:last-of-type {
  margin-bottom: 24px;
}

.registration-label-text {
  margin-bottom: 4px;
  color: $grayest;
}

.registration-input-container {
  display: block;
  justify-content: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 32px;

    @include --mobile-lg {
      margin-bottom: 16px;
    }
  }

  .input-label,
  .select {
    display: flex;
    flex-direction: column-reverse;
  }

  .select-reset {
    right: 36px;

    svg {
      width: 18px;
      height: 12px;
      fill: rgba($dark, 0.54);
    }
  }

  .password-input-eye {
    right: 18px;
  }

  .input-label-text,
  .select-label-text {
    display: none;
  }
  .select-label-error {
    margin: 0;
  }

  .input-label-container,
  .select-label-container {
    margin-top: 8px;
  }

  input:not(.paginator-pages-current) {
    box-sizing: border-box;
    width: 100% !important;
    height: 56px;
    padding: 19px 12px;
    border-radius: 4px;
  }

  .input-input-error:not(:focus)::placeholder,
  .select-input-error:not(:focus)::placeholder {
    color: $red;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px #fff inset !important;
  }
}

.registration-input-title {
  @include text1;
  position: relative;
  z-index: 1;
  color: $grayest;

  span {
    background-color: $white;
  }
}

.registration-thanks-head {
  @include text1(bold);

  color: $grayest;
  margin-bottom: 12px;
  max-width: 382px;
}

.registration-thanks-text {
  @include text2();

  color: $grayest;
  margin-bottom: 24px;
  max-width: 382px;
}

.registration-submit-error {
  @include text2();
  max-width: 263px;
  margin-left: auto;
  margin-right: auto;
  color: $red;
  margin-bottom: 6px;
}

.registration-form-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > * {
    @include --mobile-lg {
      flex-basis: 100%;
    }

    &:not(:last-child) {
      margin-right: 16px;

      @include --mobile-lg {
        flex-basis: 100%;
        margin: 0 0 12px;
      }
    }
  }
}

.registration-input-cancel {
  @include button-outline();
  border-color: $blue;
}

.registration-input-submit {
  @include button-filled();
  background-color: $blue;
}

.registration-input-submit,
.registration-input-cancel {
  width: auto;
  height: auto;
  padding: 14px 32px;
  border-radius: 100px;

  @include --mobile-lg {
    padding: 12px 26px;
  }
}

.registration-submit-error:last-of-type {
  margin-bottom: 12px;
}

.registration-agree-label {
  @include header4(normal);
  margin: 0 auto 24px auto;
  color: $grayest;

  a {
    @include underline;
    color: $blue;
    cursor: pointer;
  }
}
