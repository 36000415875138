@import "../../assets/scss/mixins";
@import "../../assets/scss/colors";
@import "src/assets/scss/breakpoints";

.object-card {
  justify-content: space-between;
  padding: 16px 24px;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  @include --tablet {
    padding: 12px 16px;
  }

  @include --mobile-lg {
    padding: 12px 20px;
  }
}

.object-card:hover {
  background-color: $blue-background-light;
}

.object-card-info {
  @include text1();

  display: grid;
  grid-template-areas: "name plan-end percentage status subscribe delete";
  grid-template-columns: 3fr 1.2fr 1fr 1.2fr min-content min-content;
  grid-auto-columns: 40px;
  grid-column-gap: 24px;

  @include --tablet {
    grid-template-areas: "name plan-end percentage status delete"
  "subscribe subscribe subscribe subscribe subscribe";
    grid-template-columns: 3fr 1.2fr 1fr 1.2fr min-content;
    grid-column-gap: 12px;
    grid-row-gap: 16px;
  }

  @include --mobile-lg {
    grid-template-areas: "name" "plan-end" "percentage" "status" "subscribe" "delete";
    grid-template-columns: 1fr;
  }
}

.object-card-subscribe {
  grid-area: subscribe;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;

  @include --tablet {
    justify-content: flex-start;
    width: auto;

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.object-card-label {
  @include text1(bold);
}

.object-card-name {
  grid-area: name;
}

.object-card-plan-end {
  grid-area: plan-end;
  align-self: center;
  justify-self: flex-start;
}

.object-card-contractor {
  grid-area: contractor;
  align-self: center;
  justify-self: center;
}

.object-card-percentage {
  grid-area: percentage;
  width: 100%;
  justify-self: flex-end;
  align-self: center;
}

.object-card-status {
  grid-area: status;
  justify-self: flex-start;
  align-self: center;
}

.object-card-delete {
  grid-area: delete;
  justify-self: flex-start;
  align-self: center;
  width: 100%;

  > button {
    @include button-text;

    > svg {
      fill: none;
    }
  }
}

.object-card-controls {
  display: flex;

  > *:not(:last-child) {
    margin-right: 16px;
  }

  .object-card-delete {
    width: auto;

    > button {
      @include button-outline;

      > svg {
        fill: none;
      }
    }
  }
}

.object-card-detailed {
  @include button-outline;
  flex: 1 1 auto;
}

.object-card-name,
.object-card-status,
.object-card-percentage,
.object-card-contractor,
.object-card-plan-end,
.object-card-subscribe {
  @include --mobile-lg {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}

.object-card-name-head {
  display: flex;
  margin-bottom: 2px;
  cursor: pointer;
  color: $accent;
}

.object-card-name-head-text {
  @include underline;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.object-card-name-address,
.object-card-name-mobile {
  color: $grayest;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.object-card-name-mobile {
  -webkit-line-clamp: 2;
  display: none;
  order: 2;
}

.object-card-contractor {
  width: 100%;
}
.object-card-contractor-text {
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 0;
  width: 100%;
}

.closed-object-status {
}

.object-card-subscribe-text{
  @include text1();

  color: $grayest;
  margin-right: 12px;
}

//@media all and (max-width: 835px) {
//  .object-card {
//    flex-wrap: wrap;
//  }
//
//  //.object-card-percentage {
//  //  max-width: calc(100% - 110px);
//  //}
//
//  .object-card-name {
//    margin-bottom: 12px;
//    max-width: 180px;
//  }
//
//  .object-card-contractor {
//    max-width: 180px;
//  }
//
//  .object-card-plan-end {
//    max-width: 100px;
//  }
//  .object-card-info {
//    grid-template-areas: "name plan-end contractor";
//    grid-template-columns: 2fr 1fr 2fr;
//  }
//  .object-card-percentage {
//    grid-column-start: 1;
//    grid-column-end: 3;
//
//  }
//  .object-card-status {
//    grid-column-start: 3;
//  }
//}
//@media all and (max-width: 590px) {
//  .object-card-info {
//    font-size: 12px;
//    font-weight: 500 !important;
//    grid-row-gap: 8px;
//  }
//
//  .object-card-contractor,
//  .object-card-plan-end {
//    font-weight: 500;
//  }
//
//  .object-card-name-head {
//    margin-bottom: 0;
//  }
//  .object-card-name {
//    margin-bottom: 0;
//  }
//  .object-card-subscribe-text {
//    font-size: 12px;
//  }
//  .objects-header-text {
//    font-size: 24px !important;
//  }
//  .object-card-name-head-text {
//    -webkit-line-clamp: 3 !important;
//  }
//}
//
//@media all and (max-width: 434px) {
//  .objects-header-controls {
//    display: flex;
//    flex-direction: column;
//    align-items: flex-start !important;
//  }
//  .objects-header-controls-button-mobile {
//    display: none !important;
//  }
//}
//
//@media all and (max-width: 380px) {
//  .object-card-info {
//    display: flex;
//    flex-direction: column;
//  }
//  .object-card-plan-end,
//  .object-card-contractor{
//    align-self: flex-start;
//  }
//  .objects-header-controls-switch {
//    justify-content: flex-start !important;
//    min-width: unset !important;
//  }
//}


