@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.stage-comments-container {
  max-width: 732px;
}

.stage-comments-head {
  @include header3();

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.stage-comments {
  @include reset-list;
  padding-bottom: 32px;
  border-bottom: 1px solid $stroke;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.stage-comment {
  @include text1();

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.stage-comment-empty {
  @include text1();

  color: $dark;
}

.stage-comment-head {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.stage-comment-name {
  @include header4();

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  @include --mobile-lg {
    display: flex;
    flex-direction: column-reverse;
  }
}

.stage-comment-text {
  word-break: break-word;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.stage-comment-date,
.stage-comment-position {
  @include text1();
  color: $grayest;
}

.stage-comment-date {
  &:not(:first-child) {
    margin-left: 6px;

    @include --mobile-lg {
      margin-left: 0;
      margin-bottom: 4px;
    }
  }
}

.stage-comments-paginator{
  margin-bottom: 24px;
  max-width: 215px;
  margin-left: auto;
}

.stage-comments-add {
  &:not(:first-child) {
    margin-top: 32px;
  }
}

.stage-comments-add-input{
  margin-bottom: 12px;
}

.stage-comments-add-button{
  @include button-filled();

  max-width: 150px;
  margin-left: auto;
}
