@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.account-widget {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-left: auto;
  align-items: center;
}

.account-widget-logged {
  flex: 1 1 auto;
  margin-left: 0;

  @include --mobile-lg {
    display: none;
  }
}

.account-widget-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid $stroke;

  &._without-background {
    background-color: transparent;
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0;
  }

  + * {
    margin-top: 92px;

    @include --mobile-lg {
      margin-top: 56px;
    }
  }
}

.account-widget-container {
  @include container;
  position: relative;
  min-height: 72px;
  margin: 0 auto;
  padding: 0;
  display: flex;

  @include --mobile-lg {
    min-height: 56px;
  }

  > *:not(:first-child) {
    margin-left: 24px;
  }

  > .logo {
    align-self: center;

    &:not(:last-child) {
      margin-right: auto;
    }

    img {
      display: block;
      margin: 0;
    }
  }
}

.account-widget-arrow-back {
  @include button-text;
  align-self: center;
  margin-bottom: 4px;

  @include --mobile-lg {
    margin-left: auto;
  }

  &:not(:last-child) {
    margin-right: 24px;
  }
}

.account-widget-user-container {
  position: relative;
  display: flex;
}

.account-widget-button {
  @include text1();
  background-color: transparent;
  border: none;
  color: #184870;
  text-decoration: none;
  &.button {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 69, 120, 0.2);
    backdrop-filter: blur(42px);
    border-radius: 80px;
    padding: 14px 32px;
  }
}

.account-widget-button:hover {
  text-decoration: underline;
}

.account-widget-button:first-child {
  margin-right: 50px;
}

.account-widget-active {
}

.account-widget-info {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.account-widget-avatar {
  margin-right: 4px;
}

.account-widget-name {
  @include text1();

  display: block;
  color: $grayest;
  text-align: right;
  margin-bottom: 6px;
}

.account-widget-role {
  @include text2(bold);

  color: $grayest;
  letter-spacing: 0.1em;
  text-align: right;
  text-transform: uppercase;
}

.account-widget-arrow-container {
  padding-top: 3px;
}

.account-widget-arrow {
  display: flex;
}

.account-widget-menu-toggle {
  @include reset-btn;
  @include button-text;
  align-self: center;
  color: $accent;

  @include --mobile-lg {
    //margin-left: auto;
  }

  > svg {
    width: 24px;
    height: 24px;
  }
}

.account-widget-control {
  @include reset-btn;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 1px solid $grayer;
  border-radius: 8px;
  color: $accent;
  background-color: #f5fcff;
  cursor: pointer;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  &:not(:first-child) {
    margin-left: 16px;
  }

  &._notify {
    &::after {
      position: absolute;
      top: -3px;
      right: -3px;
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $red;
      content: "";
    }
  }

  > svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    border-color: $grayest;
    background-color: $blue-background-light;
  }

  &:active {
    border-color: $bluest;
    background-color: $blue-background;
  }

  &:disabled {
    color: $grayest;
    border-color: $grayest;
    background-color: $white;
  }
}

.account-widget-dropdown {
  display: none;
  position: absolute;
  min-width: 150px;
  top: calc(100% + 4px);
  right: 0;
  background: $white;
  border: 1px solid $stroke;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  &::after {
    position: absolute;
    right: 0;
    bottom: 100%;
    display: block;
    width: 40px;
    height: 5px;
    content: "";
  }

  &:hover {
    display: block;
  }
}

.account-widget-user-container:hover + .account-widget-dropdown,
.account-widget-control:hover + .account-widget-dropdown {
  display: block;
}

.account-widget-dropdown-item {
  @include text2();
  @include reset-btn;

  width: 100%;
  padding: 8px 12px 8px 12px;
  text-align: start;
  transition: color 250ms ease, background-color 250ms ease;

  &:hover {
    color: $accent;
    background: $blue-background-light;
  }

  &:focus {
    outline: none;
  }
}

.navigation {
  @include reset-list;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;

  @include --tablet {
    display: none;
  }

  &:not(:last-child) {
    margin-right: auto;
  }

  > li {
    &:not(:last-child) {
      margin-right: 30px;

      @media screen and (min-width: 1024px) and (max-width: 1120px) {
        margin-right: 20px;
      }
    }
  }

  &__link {
    @include reset-link;
    @include text1;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1px;
    border-bottom: 4px solid transparent;
    transition: border-color 250ms ease, color 250ms ease;

    &:hover {
      color: $bluest;
    }

    &:active {
      color: $accent;
    }

    &._active {
      border-bottom-color: $accent;
    }
  }
}

@media all and (max-width: 1390px) {
  .account-widget-arrow-back {
    bottom: -12px;
    left: 0;
  }
}

@media all and (max-width: 767px) {
  .account-widget-container {
  }

  .account-widget-button:first-child {
    margin-right: 16px;
  }
}
