@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.date-picker {
  background: $white;
  border: 1px solid $stroke;
  border-radius: 4px;
  min-width: 272px;
  z-index: 999;
  position: relative;
}

.date-picker-header {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-bottom: 1px solid $stroke;
}

.date-picker-header-button {
  background: none;
  border: none;
  cursor: pointer;
  height: 17px;
  width: 17px;

  svg {
    fill: $grayest;
    transition: fill 250ms ease;
  }

  @include hover {
    svg {
      fill: $accent;
    }
  }

  &:focus {
    outline: none;
  }

  &:first-of-type {
    transform: rotate(180deg);
  }
}

.date-picker-header-month,
.date-picker-header-year {
  @include text1();

  border: 1px solid $stroke;
  border-radius: 4px;
  background: $white;
  height: 25px;
  cursor: pointer;
}

.date-picker-header-month {
  width: 120px;
  margin-left: auto;
  margin-right: 12px;
}

.date-picker-header-year {
  width: 60px;
  margin-right: auto;
}

.date-picker-calendar {
  min-height: 200px;
  padding: 12px 24px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 1fr;
}

.date-picker-day-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.date-picker-day,
.date-picker-day-selected {
  @include text1();

  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 250ms ease;

  @include hover {
    background: $grayest-background;
  }
}

.date-picker-day-selected {
  background-color: $grayest;

  @include hover {
    background: $grayest;
  }
}

.date-picker-calendar-day-name {
  @include text2();
  color: $grayest;
  text-align: center;
  justify-self: center;
  align-self: center;
}
