@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";


.solo-object {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.content-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    text-align: center;
  }
}

.solo-object-header {
  position: relative;
  z-index: 1;
}
