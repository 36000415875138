@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.info-panel-with-edit-label {
  @include flexGap(16px);
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.info-panel-with-edit-label-text {
  @include header3();
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include --tablet {
    margin-top: 16px;
  }

  > * {
    @include --mobile-lg {
      flex-basis: 100%;
    }

    &:not(:last-child) {
      margin-right: 16px;

      @include --mobile-lg {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  &-fill {
    @include button-filled();
  }
}

.info-panel-with-edit-add_contractor {
  @include button-filled();
}

.info-panel-with-edit {
  @include button-outline;

  > svg {
    fill: none;
  }
}

.solo-object-label-edit-disabled {
  cursor: not-allowed;

  svg {
    fill: $grayer;
  }
}

.info-panel-with-edit-cancel {
  svg {
    fill: $red;
  }
}
