@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.logo {
  text-align: center;
  width: 124px;

  @include --mobile-lg {
    width: 81px;
  }
}

.logo-img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.logo-text {
  @include logo();

  color: $dark;
}
