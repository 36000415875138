@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.show-image-modal-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.show-image-modal-head{
  display: flex;
  margin-bottom: 24px
}

.show-image-modal-head-close{
  height: 50px;
  width: 50px;
  margin-left: auto;
  background-color: transparent;
  border: none;
  color: $white;
  cursor: pointer;
  font-size: 20px;

  &:hover{
    background-color: $dark;
  }

  &:focus{
    outline: none;
  }
}

.show-image-modal-main{
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 148px);
  margin-bottom: 24px
}

.show-image-modal-back,
.show-image-modal-forward{
  width: 50px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 25px;

  color: $white;

  &:hover{
    background-color: $dark;
  }

  &:focus{
    outline: none;
  }

  &:disabled{
    color: $grayest;
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.show-image-modal-image{
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    max-width: calc(100vw - 148px);
  }
}

.show-image-modal-controls{
  display: grid;
  grid-template-areas: "filler name buttons";
  grid-template-columns: 1fr 320px 1fr;
  grid-column-gap: 12px;
  grid-template-rows: 50px;
}

.show-image-modal-controls-name{
  max-width: 500px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  word-break: break-all;
  grid-area: name;
}

.show-image-modal-controls-buttons{
  grid-area: buttons;
  justify-self: flex-end;
  display: flex;
}

.show-image-modal-controls-download,
.show-image-modal-controls-delete{
  padding: 10px;
  height: 50px;
  background-color: transparent;
  border: none;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    background-color: $dark;
  }

  &:focus{
    outline: none;
  }
}

.show-image-modal-controls-download{
  @include text1();

  height: 30px;
  margin-left: auto;
  text-decoration: none;
}

.show-image-modal-background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $dark;
  opacity: 0.6;
  z-index: -1;
}

@media all and (max-width: 1150px) {
  .show-image-modal-container {
    margin-left: 40px;
    width: 90vw;
  }
  .show-image-modal-controls {
    grid-template-columns: unset !important;
  }
}
