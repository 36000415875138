@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.contractors {
  &-card {
    border: 1px solid $stroke;
    border-radius: 8px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @include --mobile-lg {
      border: none;
    }

    &__head,
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr  0.5fr  180px;
      grid-column-gap: 24px;
      padding: 12px 24px;
    }

    &__head {
      @include header4();
      color: $accent;
      background-color: $gray-background;

      &:not(:last-child) {
        border-bottom: 1px solid $stroke;
      }
    }

    &__content {
      @include text1;
      transition: background-color 250ms ease;

      @include hover {
        background-color: $blue-background-light;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $stroke;
      }

      @include --mobile-lg {
        display: flex;
        flex-direction: column;
        margin: 0 -20px;
        border-bottom: 1px solid $stroke;

        > * {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        &:first-child {
          border-top: 1px solid $stroke;
        }
      }

      button:not([class]) {
        @include button-filled();
      }

      &-name, &-phone, &-email, &-control {
        display: flex;
        align-items: center;
        font-size: 14px;

        @include --mobile-lg {
          display: grid;
          grid-template-columns: 100px 1fr;
          grid-column-gap: 16px;
        }

        > a {
          @include reset-link;
          @include underline;
          color: $accent;
          transition: color 250ms ease, text-decoration-color 250ms ease;

          @include hover {
            color: $bluest;
          }
        }
      }

      &-control {
        @include --mobile-lg {
          display: flex;
        }
      }
    }

    &__label {
      color: $grayest;
    }
  }
}