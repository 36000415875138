@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.checkbox {
  @include text1();
  display: flex;
  align-items: center;

  input {
    position: absolute;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    ~ span {
      cursor: pointer;
    }

    &:disabled ~ span {
      cursor: not-allowed;
    }

    &:checked + .checkbox__box::after {
      content: "";
    }

    @include hover {
      &:not(:disabled) + .checkbox__box {
        border-color: $accent;
      }
    }

    @include active {
      &:not(:disabled) + .checkbox__box {
        border-color: $bluest;
      }
    }

    &:disabled + .checkbox__box {
      opacity: 0.5;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 18px;
    height: 18px;
    color: $grayest;
    border: 2px solid $grayest;
    border-radius: 2px;
    transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &::after {
      display: block;
      width: 100%;
      height: 100%;
      color: $grayest;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 13L9 17L19 7' stroke='%23184870' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: contain;
    }
  }
}
