@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.editable-select-field {
}

.editable-select-field-container {
  display: flex;
  flex-direction: column;

  @include --mobile-lg {
    flex-direction: column-reverse;
  }
}

.editable-select-field-label {
  @include text1();
  color: $grayest;

  @include --mobile-lg {
    margin-bottom: 4px;
  }
}

.editable-select-field-value {
  @include text1();
  color: $dark;

  @include --from-tablet {
    margin-bottom: 4px;
  }
}
