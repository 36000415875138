@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.object-links {
  &-head {
    @include text1();
    color: $grayest;

    display: grid;
    grid-template-columns: 1fr 1fr 180px 80px;
    grid-column-gap: 24px;
    margin-bottom: 12px;
  }
  &-content {
    @include text1();
    color: $dark;

    display: grid;
    grid-template-columns: 1fr 1fr 180px 80px;
    grid-column-gap: 24px;
    padding: 8px 0;
    &-link {
      color: $bluest;
      text-decoration: none;
      word-break: break-all;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include --tablet {
  .object-links {
    &-head {
      display: none;
    }
    &-content {
      &-name {
        grid-column-start: 1;
        grid-column-end: 4;
      }
      &-address {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
        font-weight: normal;
      }
      &-end {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
        font-weight: normal;
      }
      &-link {
        grid-row-start: 1;
        grid-row-end: 3;
        align-self: center;
      }
    }
  }
}


@include --mobile-lg {
  .object-links-content {
    &-name, &-address {
      grid-column-start: 1;
      grid-column-end: 4;
    }
    &-link {
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}
