@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.select {
  @include text1();
  position: relative;
  width: 100%;
}

.select-label {
  display: block;
  width: 100%;
  margin-bottom: 0;
}

.select-label-text {
  @include text1();
  display: inline-block;
  color: $grayest;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.select-label-error {
  @include text1();
  display: inline-block;
  color: $red;

  &:not(:first-child) {
    margin-top: 4px;
  }
}

.select-input,
.select-input-error {
  @include text1();
  width: 100%;
  border: 1px solid $stroke;
  border-radius: 4px;
  padding: 9px 36px 9px 12px;
  color: $dark;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  ._rounded & {
    border-radius: 100px;
  }

  &:focus {
    outline: none;
    border-color: $blue;
    background-color: $white;
  }

  &:focus ~ .select-arrow {
    svg {
      fill: $blue;
    }
  }

  &:focus ~ .select-menu {
    display: block;
  }
}

.select-input-error {
  border-color: $red;
  background-color: $red-background-light;

  & ~ .select-arrow {
    svg {
      fill: $red;
    }
  }
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateY(-50%);

  svg {
    fill: $grayer;
  }
}

.select-reset {
  position: absolute;
  top: 50%;
  right: 36px;
  height: 24px;
  display: flex;
  align-items: center;
  transform: translateY(-50%);

  svg {
    height: 8px;
    width: 12px;
    fill: red;
    cursor: pointer;
  }
}

.select-menu {
  display: none;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  margin-bottom: 0;
  background: $white;
  list-style: none;
  border: 0.5px solid $stroke;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  transition: 250ms;
  z-index: 100;

  &:hover {
    display: block;
  }
}

.select-label-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.select-name-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.select-name {
  @include text1();

  color: $grayest;
  margin-bottom: 4px;
  cursor: pointer;
}

.select-name-error {
  @include text1();

  color: $red;
  margin-bottom: 4px;
  text-align: end;
}

.select-item {
  @include text1();

  color: $dark;
  border: none;
  padding: 7px 12px;
  cursor: pointer;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    color: $accent;
    background-color: $blue-background-light;
  }

  &.selected {
    background-color: $semi-gray;
    pointer-events: none;
  }
}
