@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.summary{
  display: grid;
  grid-template-areas:
    "list"
    "controls";
  grid-template-rows: 1fr 50px;
  height: calc(96% - 12px);
}

.summary-list{
  margin-bottom: 12px;
  grid-area: list;
  overflow-y: scroll;
}

.summary-controls{
  grid-area: controls;
  display: flex;
  justify-content: space-between;
  &-right,
  &-left {
    display: flex;
  }
}

.summary-controls-checkbox{
  @include text1();

  height: 25px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

.summary-controls-time-block {
  > *:not(:last-child) {
    margin-right: 8px;
  }

  input {
    padding: 3px;
  }

  .react-datepicker__close-icon::after {
    width: 12px;
    height: 12px;
  }
}

.summary-controls-checkbox-input{
  margin-right: 6px;
}

.summary-controls-button1,
.summary-controls-button2{
  @include button-filled();

  width: 130px;
}

.summary-controls-button1{
  margin-right: 12px;
}

@media all and (max-width: 560px) {
  .summary {
    height: unset;
    grid-template-rows: unset;
  }
  .summary-controls {
    flex-direction: column;
    &-left {
      margin-bottom: 12px;
    }
  }
}
@media all and (max-width: 410px) {
  .summary-controls {
    &-right {
      flex-direction: column;
      button {
        margin-top: 12px;
        margin-bottom: 12px;
        width: 100%;
      }
    }
  }
  .summary-controls-checkbox {
    margin-bottom: 8px;
  }
}
