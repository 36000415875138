@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.add-object-info-form {
  display: grid;
  grid-area: info;
  grid-template-areas:
    "address plan-beg plan-end"
    "description description description"
    "ref ref ref"
    "national-project job-type object-type";
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 12px;
  grid-column-gap: 12px;
}

.add-object-info-form-address {
  grid-area: address;
}

.add-object-info-form-plan-beg {
  grid-area: plan-beg;
}

.add-object-info-form-plan-end {
  grid-area: plan-end;
}

.add-object-info-form-description {
  grid-area: description;
}

.add-object-info-form-ref{
  grid-area: ref;
}

.add-object-info-form-national-project {
  grid-area: national-project;
}

.add-object-info-form-job-type {
  grid-area: job-type;
}

.add-object-info-form-object-type {
  grid-area: object-type;
}

@media all and (max-width: 620px) {
  .add-object-info-form {
    display: flex;
    flex-direction: column;
  }
}
