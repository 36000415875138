@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.input-file-drop {
  &__message {
    @include text2;
    display: inline-block;
    color: $grayest;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
