@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.add-object-archive-content {
  border: 1px solid $stroke;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &-head {
    @include header4();

    color: $accent;
    display: grid;
    grid-template-columns: 1fr 1fr 150px 80px 20px;
    grid-column-gap: 24px;
    padding: 16px 24px;
    background-color: $gray-background;

    &:not(:last-child) {
      border-bottom: 1px solid $stroke;
    }
  }
  &-main {
    @include text1();

    color: $dark;
    display: grid;
    grid-template-columns: 1fr 1fr 150px 80px 20px;
    grid-column-gap: 24px;
    padding: 16px 24px;
    transition: background-color 250ms ease;

    &:not(:last-child) {
      border-bottom: 1px solid $stroke;
    }

    @include hover {
      background-color: $blue-background-light;
    }
  }

  &-link {
    @include reset-link;
    @include underline;
    color: $accent;
    word-break: break-all;
    transition: color 250ms ease, text-decoration-color 250ms ease;

    @include hover {
      color: $bluest;
    }
  }

  &-name, &-address, &-link, &-controls, &-end {
    align-self: center;
  }
  &-controls {
    @include button-text();
  }
}
.add-object-archive-modal-content-all {
  margin-bottom: 24px;
}

.add-object-archive {
  &-head {
    @include text1();

    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      @include button-filled();

      width: 150px;
    }
  }
  &-modal {
    display: grid;
    grid-template-rows: 24px 1fr 50px;
    height: 86%;
    &-head {
      @include text1();

      color: $grayest;
      display: grid;
      grid-template-columns: 0.1fr 1fr 1fr 180px 0.4fr;
      grid-column-gap: 24px;
      margin-bottom: 12px;
      padding: 0 24px;
    }
    &-content {
      @include text1();

      color: $dark;
      display: grid;
      grid-template-columns: 0.1fr 1fr 1fr 180px 0.4fr;
      grid-column-gap: 24px;
      cursor: pointer;
      padding: 12px 24px;
      transition: 0.2s;
      &-link {
        color: $bluest;
        text-decoration: none;
        word-break: break-all;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-content:hover {
      background-color: $gray;
    }
    &-button {
      display: flex;
      justify-content: flex-end;
      padding: 0 48px;
      button {
        @include button-filled();

        width: 150px;
      }
    }
  }
}

.solo-object-add-links {
  display: flex;
  //flex-direction: column;
  justify-content: space-between;

  > * {
    //flex: 1 0 auto;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  > button {
    @include button-filled();
  }

  &-controls {
    display: flex;

    @include --mobile-lg {
      width: 100%;
    }

    > * {
      flex: 1 0 auto;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    .save {
      @include button-filled;
    }
    .cancel {
      @include button-outline();
    }
  }
}

@include --tablet {
  .add-object-archive-content {
    &-head {
      display: none;
    }
    &-main {
      grid-template-columns: 1fr 40px;
      grid-column-gap: 8px;
    }
    &-name {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &-address {
      font-weight: normal;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    &-end {
      font-weight: normal;
      grid-row-start: 3;
      grid-row-end: 4;
    }
    &-link {
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &-controls {
      grid-row-start: 1;
      grid-row-end: 4;
      grid-column-start: 2;
      grid-column-end: 3;
      align-self: center;
    }
  }


  .add-object-archive-modal-head {
    display: none;
  }
  .add-object-archive-modal {
    display: flex;
    flex-direction: column;
  }
  .add-object-archive-modal-button {
    padding-bottom: 24px;
  }
  .add-object-archive-modal-content {
    grid-template-columns: 20px 1fr 60px;
    &-name {
      grid-column-start: 2;
      grid-column-end: 3;
    }
    &-address {
      font-weight: normal;
      grid-column-start: 2;
      grid-column-end: 3;
    }
    &-checkbox {
      grid-row-start: 1;
      grid-row-end: 4;
      align-self: center;
    }
    &-end {
      font-weight: normal;
      grid-column-start: 2;
      grid-column-end: 3;
    }
    &-link {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 3;
      align-self: center;
      font-weight: normal;
    }
  }
}


@include --mobile-lg {
  .solo-object-add-links {
    width: 100%;
    > button {
      width: 100%;
    }
  }
  .add-object-archive-modal-content {
    &-name {
      grid-column-start: 2;
      grid-column-end: 4;
    }
    &-address {
      font-weight: normal;
      grid-column-start: 2;
      grid-column-end: 4;
    }
    &-checkbox {
      grid-row-start: 1;
      grid-row-end: 3;
      align-self: center;
    }
    &-end {
      font-weight: normal;
      grid-column-start: 2;
      grid-column-end: 4;
    }
    &-link {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 4;
      grid-row-end: 5;
    }
  }
}
