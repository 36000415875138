@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.choose-user {
  &-list {
    border: 1px solid $stroke;
    border-radius: 8px;

    @include --mobile-lg {
      margin: 0 -20px;
    }

    &:not(:last-child) {
      margin-bottom: 24px;

      @include --mobile-lg {
        margin-bottom: 16px;
      }
    }

    &__label {
      display: block;
      color: $grayest;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    &__card,
    &__head {
      max-width: 100%;
      padding: 12px 16px;
      //display: grid;
      //grid-template-columns: 2fr 1.5fr 1fr 1fr;
      //grid-column-gap: 24px;
      transition: background-color 250ms ease;

      @include --mobile-lg {
        padding: 12px 20px;
      }

      @include hover {
        background-color: $blue-background-light;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $stroke;
      }

      .name,
      .email,
      .phone,
      .org-name {
        @include text1();
        align-self: center;
        word-break: break-word;
      }

      .email,
      .phone {
        > a {
          @include reset-link;
          @include underline;
          color: $accent;
        }
      }

      .assign {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > button {
          @include button-text;
        }
      }

      @include --from-tablet {
        display: flex;
        justify-content: space-between;

        > *:not(:last-child) {
          margin-right: 12px;
        }

        > *:first-child {
          flex: 1 1 calc(100% / 7.5 * 2);
          max-width: calc(100% / 7.5 * 2);
        }

        > *:nth-child(2) {
          flex: 1 1 calc(100% / 7.5 * 1.5);
          max-width: calc(100% / 7.5 * 1.5);
        }

        > *:nth-child(3) {
          flex: 1 1 calc(100% / 7.5);
          max-width: calc(100% / 7.5);
        }

        > *:nth-last-child(2) {
          flex: 1 1 calc(100% / 7.5 * 2);
          max-width: calc(100% / 7.5 * 2);
        }

        > *:last-child {
          flex: 1 1 calc(100% / 7.5);
          max-width: calc(100% / 7.5);
        }
      }

      .name,
      .email,
      .phone,
      .org-name {
        align-self: center;
      }
    }

    &__head {
      @include header4();
      background-color: $gray-background;
      color: $accent;
    }
  }

  .create-user-block {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
}

@include --tablet {
  .choose-user {
    &-list {
      &__card {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr !important;
        grid-gap: 8px;
        .name {
          grid-column-start: 1;
          grid-column-end: 4;
        }
        .org-name {
          grid-column-start: 1;
          grid-column-end: 4;
          grid-row-start: 2;
          grid-row-end: 3;
        }
        .email,
        .phone {
          font-weight: normal;
        }
        .assign {
          //grid-row-start: 2;
          //grid-row-end: 3;
        }
      }
    }
  }
  .user-search-for-choose {
    display: flex !important;
    flex-direction: column;
  }
}

@include --mobile-lg {
  .choose-user {
    &-list {
      &__card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        //grid-template-columns: 1fr 0.5fr 1fr !important;

        .name,
        .email,
        .phone,
        .org-name {
          //display: grid;
          //grid-template-columns: 1fr 2fr;
          //width: 100%;
          align-self: flex-start;
        }
        //.name,
        //.org-name {
        //  grid-column-start: 1;
        //  grid-column-end: 4;
        //}
        //.email,
        //.phone {
        //  font-weight: normal;
        //  grid-row-start: 3;
        //  grid-row-end: 4;
        //  margin-bottom: 8px;
        //}
        //.email {
        //  grid-column-start: 1;
        //  grid-column-end: 3;
        //}
        //.phone {
        //  grid-column-start: 3;
        //  grid-column-end: 4;
        //}
        //.assign {
        //  grid-row-start: 4;
        //  grid-row-end: 5;
        //  grid-column-start: 1;
        //  grid-column-end: 4;
        //}
      }
    }
  }
}
