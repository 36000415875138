@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.map {
  &-map:not(:last-child) {
    margin-bottom: 16px;
  }

  .ol-zoom,
  .ol-full-screen {
    display: flex;
    margin-top: 22px;
    margin-left: 6px;
    background-color: transparent;

    @include hover {
      background-color: transparent;
    }

    > *:not(:last-child) {
      margin-right: 8px;
    }

    > button {
      position: relative;
      width: 36px;
      height: 36px;
      border: 1px solid $white;
      border-radius: 8px;
      font-size: 0 !important;
      line-height: 0 !important;
      background-color: $blue;
      cursor: pointer;
      transition: color 250ms ease, background-color 250ms ease,
        border 250ms ease;
    }

    .ol-zoom-in,
    .ol-zoom-out {
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        border-radius: 100px;
        background-color: $white;
        transform: translate(-50%, -50%);
      }

      &::before {
        width: 10px;
        height: 2px;
        content: "";
      }
    }

    .ol-zoom-in {
      &::after {
        width: 2px;
        height: 10px;
        content: "";
      }
    }
  }

  .ol-full-screen {
    margin-left: 0;
    margin-right: 6px;
  }

  .ol-full-screen-true,
  .ol-full-screen-false {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      display: block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 9L20 4M20 4V8M20 4H16M9 15L4 20M4 20V16M4 20H8' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      content: "";
    }
  }

  .ol-full-screen-true::before {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 20L15 15M15 15V19M15 15H19M4 20L9 15M9 15V19M9 15H5M20 4L15 9M15 9V5M15 9H19M4 4L9 9M9 9V5M9 9H5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }

  &-footer {
    position: relative;
    z-index: 1;
    margin-top: -24px;
    padding: 16px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
      0px 2px 24px rgba(0, 0, 0, 0.08);
  }

  &-layers-head {
    @include header4();
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid $stroke;
    color: $grayest;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &-layers {
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    @include --tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include --mobile-lg {
      grid-template-columns: 1fr;
    }
  }

  &-layer {
    label {
      display: flex;
      gap: 8px;
      align-items: start;
    }

    input {
      margin-top: 2px;
    }
  }

  &-draw {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    grid-gap: 16px;

    @include --tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include --mobile-lg {
      grid-template-columns: 1fr;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &-button {
      @include button-outline();

      &._add-mark {
        @include --from-tablet {
          margin-right: auto;
        }
      }

      &._add-polygon,
      &._remove-polygon,
      &._remove-polygons {
        @include --tablet {
          grid-row: 2 / 3;
        }

        @include --tablet {
          grid-row: auto / auto;
        }
      }

      &.-destructive {
        border-color: $red;

        &:not(:hover) {
          color: $red;
        }

        &:hover {
          background-color: $red;
        }
      }

      &:disabled {
        opacity: 0.3;
      }
    }

    &-button-selected {
      @include button-filled();

      &.-destructive {
        border-color: $red;
        background-color: $red;
      }
    }

    &-area {
      text-align: right;
      margin-left: auto;
      color: $grayest;

      @include --tablet {
        grid-column: 3 / 4;
      }

      @include --mobile-lg {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
    }
  }
}
