@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.file-solo-container {
  @include flexGap();
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.file-solo {
  display: flex;
  align-items: center;

  //&:not(:last-child) {
  //  margin-bottom: 16px;
  //}
}

.file-solo-icon {
  height: 100%;
  background: $white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;

  svg {
    width: 26px;
    height: 34px;
  }
}

.file-solo-info {
  padding-top: 3px;
  padding-bottom: 3px;
}

.file-solo-name {
  @include text1();
  margin-bottom: 4px;
  color: $accent;
}

.file-solo-size {
  @include text2();

  color: $grayest;
}

.file-solo-controls {
  display: flex;

  @include --mobile-lg {
    flex-basis: 100%;
  }

  > * {
    flex: 1 0 auto;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.file-solo-download,
.file-solo-delete {
  @include button-outline;
}

.file-solo-empty,
.file-solo-empty-hover,
.file-solo-empty-filler {
  @include text1();

  color: $grayest;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px dashed $grayer;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: background-color 250ms ease, border 250ms ease, color 250ms ease;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.file-solo-empty-hover {
  border: 2px solid $grayer;
  background-color: $gray;
  color: $dark;
}

.file-solo-empty-filler {
  cursor: auto;
}

.file-solo-empty-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}
