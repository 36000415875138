@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.stage-header {
  display: grid;
  grid-template-areas: "name name status" "edit empty empty";
  grid-template-columns: 200px 1fr 40px;
  grid-column-gap: 16px;
  grid-row-gap: 24px;

  @include --mobile-lg {
    grid-template-areas: "name name name" "edit edit status";
  }
}

.stage-header-name {
  grid-area: name;

  .input-field-text {
    @include header2();
  }
}

.stage-header-tab {
  grid-area: tab;
  align-self: flex-end;
}

.stage-header-edit,
.stage-header-edit-active,
.stage-header-edit-disabled {
  @include button-outline;
  grid-area: edit;
}

