@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.input-text-area {
  width: 100%;
  min-height: 100%;
  margin-bottom: 0;
}

.input-text-area-info {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.input-text-area-label,
.input-text-area-error {
  @include text1();

  cursor: pointer;
  color: $grayest;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.input-text-area-error {
  color: $red;
}

.input-text-area-input,
.input-text-area-input-error {
  @include text1();

  color: $dark;
  display: block;
  width: 100%;
  min-height: 50px;
  border: 1px solid $stroke;
  border-radius: 4px;
  padding: 6px 12px 6px 12px;
  resize: vertical;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  &:focus {
    border-color: $blue;
    outline: none;
  }
}

.input-text-area-input-error {
  border-color: $red;
}
