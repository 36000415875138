@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.objects-list {
  width: 100%;
  height: 100%;
  border: 1px solid $stroke;
  border-radius: 8px;

  @include --mobile-lg {
    border: none;
  }

  &:not(:last-child) {
    margin-bottom: 24px;

    @include --mobile-lg {
      margin-bottom: 16px;
    }
  }
}

.objects-list-head {
  @include header4();
  color: $accent;
  padding: 16px 24px;
  display: grid;
  grid-template-areas: "name plan-end percentage status subscribe delete";
  grid-template-columns: 3fr 1.2fr 1fr 1.2fr min-content min-content;
  grid-auto-columns: min-content;
  grid-column-gap: 24px;
  list-style: none;
  background-color: $gray-background;

  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }

  @include --tablet {
    grid-template-areas: "name plan-end percentage status delete";
    grid-template-columns: 3fr 1.2fr 1fr 1.2fr min-content;
    padding: 12px 16px;
    grid-column-gap: 12px;
  }

  @include --mobile-lg {
    display: none;
  }
}

.objects-list-head-delete {
  grid-area: delete;
  width: 20px;
}

.objects-list-head-end {
  grid-area: plan-end;
  justify-self: flex-start;
  width: 100%;
}

.objects-list-head-contractor {
  justify-self: center;
  grid-area: contractor;
  width: 100%;
}

.objects-list-head-complete {
  justify-self: flex-end;
  grid-area: percentage;
  width: 100%;
}

.objects-list-head-status {
  justify-self: flex-end;
  grid-area: status;
  width: 100%;
}

.objects-list-head-subscribe {
  justify-self: center;
  grid-area: subscribe;
  width: 120px;
  text-align: center;
}

.objects-list-item {
  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }

  @include --mobile-lg {
    margin: 0 -20px;
    border-bottom: 1px solid $stroke;

    &:first-child {
      border-top: 1px solid $stroke;
    }
  }
}
