@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.global-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(167, 167, 167, 0.4);
  backdrop-filter: blur(10px);

  > * {
    box-sizing: border-box;
  }

  &__content {
    position: relative;
    max-width: calc(100% - 40px);
    margin: 40px 20px 40px;
    padding: 40px 32px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0 11px 15px rgba(0, 0, 0, 0.2), 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12);

    &._container {
      width: 100%;
      max-width: 1440px;
    }

    @include --mobile-lg {
      max-width: calc(100% - 32px);
      margin: 16px;
      padding: 16px;
    }
  }

  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition-duration: 300ms;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      display: block;
      width: 14px;
      height: 14px;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath d='M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7 14 1.41z' fill='%23000' fill-opacity='.54'/%3E%3C/svg%3E");
    }
  }

  .contractor-info {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
