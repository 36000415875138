@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.stage-histories-info-container {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.stage-histories-info-status {
  @include text1();
  color: $dark;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.stage-histories-info-status-span {
  font-weight: bold;
  margin-left: 6px;
}

.stage-histories-images-container {
}

.stage-histories-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, 70px);
  grid-column-gap: 6px;
  grid-template-rows: 70px 70px;
  grid-row-gap: 6px;
  list-style: none;
  border-radius: 5px;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.stage-histories-image {
  height: 70px;
  width: 70px;
  background-color: $dark;
}

.stage-histories-paginator {
  max-width: 280px;
  margin-left: auto;
  margin-bottom: 24px;
}

.stage-histories-comments-container {
}

.stage-histories-comments {
  list-style: none;
}

.stage-histories-comment {
  @include text2();

  color: $dark;
}

.stage-histories-comment-name {
  font-weight: bold;
  margin-bottom: 6px;
}

.stage-histories-comment-text {
  margin-bottom: 6px;
}

.stage-histories-comment-date {
  color: $grayest;
}

.stage-histories-comments-add {
}

.stage-histories-comments-add-text {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.stage-histories-comments-add-button {
  @include button-filled();
}
