@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.file-list-container {
  display: flex;
  flex-direction: column;
}

.file-list-controls {
  @include flexGap(16px);

  &:not(:last-child) {
    margin-bottom: 16px;

    @include --mobile-lg {
      margin-bottom: 0;
    }
  }

  @include --mobile-lg {
    > * {
      flex: 1 0 auto;
    }
  }
}


.file-list-upload {
  @include button-filled;
}

.file-list-download,
.file-list-delete {
  @include button-outline();
}

.expandable-panel-content {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 66px;
  padding: 0 0 10px 0;

  > .solo-object-add-contractor {
    @media screen and (min-width: 768px) {
      margin-left: auto;

      &:not(:last-child) {
        margin-bottom: -23px;
      }
    }
  }
}

.file-list-cards,
.file-list-cards-filler {
  height: fit-content;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.file-list {
  @include reset-list;
  //border: 1px solid $stroke;
  //border-radius: 4px;

  &:not(._estimate) {
    @include flexGap(16px);
    margin-bottom: 16px;

    .file-list-file-card {
      width: 100%;
      max-width: calc((100% - 48px) / 4);

      @include --tablet {
        max-width: calc((100% - 32px) / 3);
      }

      @include --mobile-lg {
        max-width: none;
      }

      .file-card-name {
        @include --tablet {
          max-width: 150px;
        }

        @include --mobile-lg {
          max-width: none;
        }
      }
    }
  }

  @include --mobile-lg {
    margin-bottom: 16px;
  }
}

.file-list-filler,
.file-list-filler-inactive,
.file-list-filler-drag,
.file-list-cards-filler {
  @include text1();

  height: 50px;
  display: flex;
  align-items: center;
  border: 2px dashed $stroke;
  border-radius: 5px;
  color: $grayest;
  width: 100%;
  padding-left: 24px;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  transition: background-color 250ms ease, border 250ms ease, color 250ms ease;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.file-list-filler-inactive {
  cursor: auto;
}

.file-list-filler-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}

.file-list-cards-filler,
.file-list-filler-drag {
  border: 2px solid $grayer;
  background-color: $grayer-background-light;
  color: $dark;
}
