@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container-wrapper {
  padding: 16px 16px 0;

  &:not(:last-child) {
    margin-bottom: 20px;

    @include --tablet {
      margin: 0;
    }
  }
}

.content-card {
  padding: 40px 32px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);

  &._narrow {
    max-width: 840px;
  }

  @include --tablet {
    padding: 24px;
  }

  @include --mobile-lg {
    padding: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__head {
    @include flexGap(16px);
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @include --mobile-lg {
      > * {
        flex-basis: 100%;
      }
    }
  }

  &__title {
    @include header3();
  }

  > .content-card__title:not(:last-child) {
    margin-bottom: 16px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $stroke;
    }

    > .content-card__title:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.container {
  @include container;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__head {
    @include flexGap(24px);
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @include --mobile-lg {
      @include flexGap(16px);

      > * {
        flex-basis: 100%;
      }
    }
  }

  &__title {
    @include header1;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}