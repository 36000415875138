@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/colors";

.profile-container {
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    &-text {
      @include header1();
      margin-bottom: 0;
      color: $dark;
    }
    &-buttons {
      display: flex;

      > *:not(:last-child) {
        margin-right: 16px;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &-save,
      &-edit,
      &-cancel {
        min-width: 135px;

        @include --mobile-lg {
          flex: 1 1 calc(50% - 8px);
        }
      }

      &-save {
        @include button-filled();
      }
      &-edit {
        @include button-outline;

        svg {
          fill: none;
        }
      }

      &-cancel {
        @include button-outline();
      }
    }
  }
  &-form {
    .input-field {
      margin-bottom: 12px;
      max-width: 440px;
    }

    .editable-select-field {
      margin-bottom: 12px;
      width: 440px;
    }

    .extended {
      @include text1(bold);
    }

    .change-password {
      max-width: 440px;
      margin-top: 32px;

      > button:not(:last-child) {
        margin-right: 16px;
      }

      button {
        min-width: 135px;

        @include --mobile-lg {
          flex: 1 1 calc(50% - 8px);
        }

        &:not([class]) {
          @include button-filled();
        }
      }

      .password-input {
        margin-bottom: 12px;
      }

      .buttons {
        display: flex;

        &:not(:first-child) {
          margin-top: 24px;
        }

        > *:not(:last-child) {
          margin-right: 16px;
        }

        .cancel {
          @include button-outline();
        }
      }
    }
  }
}
