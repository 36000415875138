@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.expandable-panel-header-open,
.expandable-panel-header-closed {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  margin-bottom: 0;
  cursor: pointer;
}

.expandable-panel-header-closed {
  padding-bottom: 12px;
}

.expandable-panel-header-open {
  margin-bottom: 12px;
}

.expandable-panel-header-name {
  @include text1(bold);
  margin-bottom: 0;
}

.expandable-panel-header-icon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  transition: .3s;

  svg {
    width: 100%;
  }
}

.expandable-panel-header-open {
  .expandable-panel-header-icon {
    transform: rotate(90deg);
  }
}
