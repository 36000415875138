@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.stage-info,
.stage-info-edit {
  @include reset-list;
  display: grid;
  grid-template-areas:
    "beg-head end-head amount-head unit-head"
    "beg end amount unit";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 4px;
}

.stage-info-edit {
  grid-template-areas:
    "beg-head end-head amount-head unit-head empty"
    "beg end amount unit controls";
  grid-template-columns: 1fr 1fr 1fr 1fr 52px;
}

.stage-info-controls {
  grid-area: controls;
  display: flex;

  > * {
    @include --mobile-lg {
      flex: 1 0 auto;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.stage-info-save,
.stage-info-cancel {
  @include button-text();

  @include --mobile-lg {
    @include button-outline;
  }
}

.stage-info-cancel {
  @include --mobile-lg {
    @include button-outline;
  }
}

.stage-info-save {
  @include --mobile-lg {
    @include button-filled;
  }
}

.stage-info-beg-head,
.stage-info-end-head,
.stage-info-amount-head,
.stage-info-unit-head,
.stage-info-completion-head {
  width: 100%;
  @include text1();

  color: $grayest;
}

.stage-info-beg-head {
  grid-area: beg-head;
}

.stage-info-end-head {
  grid-area: end-head;
}

.stage-info-amount-head {
  grid-area: amount-head;
  justify-self: center;
}

.stage-info-unit-head {
  grid-area: unit-head;
  justify-self: center;
}

.stage-info-completion-head {
  grid-area: completion-head;
  justify-self: flex-end;
}

.stage-info-beg {
  grid-area: beg;
  .input-field-text {
    text-align: left;
  }
}

.stage-info-end {
  grid-area: end;
  .input-field-text {
    text-align: left;
  }
}

.stage-info-amount {
  grid-area: amount;
  .input-field-text {
    text-align: left !important;
  }

  .input-field-text {
    text-align: center;
    .input-field-text {
      text-align: left !important;
    }
  }
}

.stage-info-unit {
  grid-area: unit;

  .input-field-text {
    text-align: left !important;
  }
}

.stage-info-completion {
  grid-area: completion;
}
.stage-info-edit {
  .stage-info-completion {
    align-self: center;
  }
}
.stage-info {
  .stage-info-completion {
    margin-top: 8px;
  }
}

@include --tablet {
  .stage-info {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "beg-head end-head"
      "beg end"
      "amount-head unit-head"
      "amount unit";
  }

  .stage-info-edit {
    grid-template-columns: 1fr 1fr 52px;
    grid-template-areas:
      "beg-head end-head empty"
      "beg end empty"
      "amount-head unit-head empty"
      "amount unit controls";
  }
  //.stage-info-amount-head {
  //  grid-row-start: 3;
  //  grid-row-end: 4;
  //  grid-column-start: 1;
  //  grid-column-end: 2;
  //}
  //.stage-info-amount {
  //  grid-row-start: 4;
  //  grid-row-end: 5;
  //  grid-column-start: 1;
  //  grid-column-end: 2;
  //}
  //.stage-info-unit-head {
  //  grid-row-start: 3;
  //  grid-row-end: 4;
  //  grid-column-start: 2;
  //}
  //.stage-info-unit {
  //  grid-row-start: 4;
  //  grid-row-end: 5;
  //  grid-column-start: 2;
  //  grid-column-end: 3;
  //}
  //.stage-info-end-head,
  //.stage-info-end {
  //  grid-column-start: 2;
  //  grid-column-end: 3;
  //}
  //.stage-info-beg-head,
  //.stage-info-beg {
  //  grid-column-end: 2;
  //}
  //.stage-info-completion-head {
  //  grid-row-start: 5;
  //  grid-row-end: 6;
  //  grid-column-start: 1;
  //  grid-column-end: 3;
  //}
  //.stage-info-completion {
  //  grid-row-start: 6;
  //  grid-row-end: 7;
  //  grid-column-start: 1;
  //  grid-column-end: 3;
  //}
  //.stage-info-save,
  //.stage-info-cancel {
  //  grid-row-start: 7;
  //  grid-row-end: 8;
  //}
  //.stage-info-save {
  //  grid-column-start: 1;
  //  grid-column-end: 2;
  //}
  //.stage-info-cancel {
  //  grid-column-start: 2;
  //  grid-column-end: 3;
  //}

  .stage-info-beg,
  .stage-info-end,
  .stage-info-amount,
  .stage-info-unit {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

@include --mobile-lg {
  .stage-info-edit,
  .stage-info {
    display: flex;
    flex-direction: column-reverse;
  }
  .stage-info-beg-head {
    order: -1;
  }
  .stage-info-end-head,
  .stage-info-beg {
    order: -2;
  }
  .stage-info-amount-head,
  .stage-info-end {
    order: -3;
  }
  .stage-info-unit-head,
  .stage-info-amount {
    order: -4;
  }
  .stage-info-completion-head,
  .stage-info-unit {
    order: -5;
  }
  .stage-info-completion {
    width: 100%;
    order: -6;
  }
  .stage-info-controls {
    order: -7;
  }
}
