@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.user-search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  margin-bottom: 24px;

  @include --mobile-lg {
    grid-template-columns: 1fr;
  }
}

.user-search-for-choose {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  margin-bottom: 24px;
}