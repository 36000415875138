@import "src/assets/scss/colors";

.password-input {
  width: 100%;
  position: relative;
}

.password-input-eye {
  position: absolute;
  top: 50%;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateY(-50%);

  svg {
    fill: $grayest;
    transition: fill 250ms ease, stroke 250ms ease;
  }

  &:hover {
    svg {
      fill: $blue;
    }
  }
}
