@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.add-object-info-container {
}

.add-object-info {
}

.add-object-info-design-project,
.add-object-info-estimates,
.add-object-info-project-estimates {
  border-top: 1px solid $grayer;
  padding: 0 24px;
}

.add-object-info-design-project {
}

.add-object-info-estimates {
}

.add-object-info-project-estimates {
  border-bottom: 1px solid $grayer;
}

.add-object-info-contractor-head {
  @include text1(bold);
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  button {
    @include button-filled();

    width: 150px;
  }
}

.add-object-info-links {
  padding: 0 24px;
}

.add-object-info-controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.add-object-info-bottom-controls {
  @include flexGap(16px);

  > * {
    @include --mobile-lg {
      flex: 1 0 auto;
    }
  }
}

.add-object-info-save {
  @include button-filled();
  margin-top: 24px;
}
