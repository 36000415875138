@import "assets/fonts/fonts.css";
@import "assets/scss/layout";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: "PT Astra Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  min-height: 100%;
}

html {
  height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
}

#root {
  flex: 1 0 auto;
}

//Убираем отображение логотипа OpenLayers
.ol-attribution.ol-unselectable.ol-control.ol-uncollapsible {
  display: none;
}

#root {
  background: url(assets/img/bg.png) top right;
  background-repeat: no-repeat;
}

.__react_component_tooltip {
  z-index: 1000 !important;
}

.no-notes {
  @include text1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  color: $grayest;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
