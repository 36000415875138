@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.user-rights-cards {
  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }
}

.user-rights-card {
  padding: 16px;
  align-items: center;
  grid-column-gap: 12px;
  display: grid;
  grid-template-columns: 1.2fr 0.8fr 1fr 1.2fr 2fr 80px 60px;
  grid-template-areas: "name phone email organization rights controls";
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  @include hover {
    background-color: $blue-background-light;
  }

  &__name,
  &__phone,
  &__email,
  &__organization,
  &__rights,
  &__role {
    @include text1();
    color: $dark;
    display: flex;
    align-items: center;
    word-break: break-word;

    > a {
      @include reset-link;
      @include underline;
      transition: color 250ms ease, text-decoration-color 250ms ease;

      @include hover {
        color: $accent;
      }
    }
  }

  &__rights {
    justify-content: center;

    @include --tablet {
      justify-content: flex-start;
    }
  }

  .controls {
    grid-column-start: 7;
    display: flex;
    justify-content: flex-end;

    > *:not(:last-child) {
      margin-right: 20px;

      @include --tablet {
        margin-right: 24px;
      }

      @include --mobile-lg {
        margin-right: 16px;
      }
    }

    button {
      @include button-text;

      @include --mobile-lg {
        @include button-outline;
        flex: 1 0 auto;
      }
    }
  }
}

@include --tablet {
  .user-rights-card {
    grid-template-columns: 1fr 1fr 1fr !important;
    grid-template-areas: unset !important;
    grid-row-gap: 12px;

    .mobile-label {
      color: $grayest;
      display: unset;
      font-weight: normal;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    &__name {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &__role {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 2;
      grid-column-end: 3;
    }
    &__phone {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &__email {
      grid-row-start: 3;
      grid-row-end: 4;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &__organization {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 4;
    }
    &__rights {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 3;
      grid-column-end: 4;
    }
    .controls {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &__name,
    &__phone,
    &__email,
    &__organization,
    &__role {
      flex-direction: column;
      align-items: flex-start !important;
      height: 100% !important;
    }
  }
}

@include --mobile-lg {
  .user-rights-card {
    display: flex;
    flex-direction: column;
    grid-row-gap: 0;

    &__name,
    &__phone,
    &__email,
    &__organization,
    &__rights,
    .controls,
    &__role {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
