$stroke: #D1DAE2;
$hover: linear-gradient(180deg, rgba(53, 53, 53, 0.75) 0%, rgba(53, 53, 53, 0.25) 100%);

$accent: #184870;

$red: #EF3E4A;
$redder: #BF323B;
$reddest: #8F252C;
$red-background: rgba($red, 0.2);
$red-background-light: rgba($red, 0.1);

$yellow: #FF9B43;
$yellower: #FF9B43;
$yellowest: #FF9B43;
$yellow-background: rgba($yellow, 0.2);
$yellow-background-light: rgba($yellow, 0.1);

$green: #0DA865;
$greener: #0A8651;
$greenest: #08653D;
$green-background: rgba($green, 0.2);
$green-background-light: rgba($green, 0.1);

$blue: #2EAADC;
$bluer: #2379A6;
$bluest: #1E618B;
$blue-background: rgba($blue, 0.2);
$blue-background-light: rgba($blue, 0.1);
$from-blue-to-blue: linear-gradient(137.16deg, #2EAADC 0%, #2189AF 100%);

$gray: #f7f6f3;
$gray-background: rgba(#777777, 0.05);
$gray-background-light: #F2F2F2;
$grayer: #D1DAE2;
$grayer-background: rgba($grayer, 0.2);
$grayer-background-light: rgba($grayer, 0.1);
$grayest: #999999;
$grayest-background: rgba($grayest, 0.2);
$grayest-background-light: rgba($grayest, 0.1);
$semi-gray: #E6E6E4;

$dark: #333333;

$white: #FFFFFF;
$black: #000000;

