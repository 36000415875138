@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.stages-item {
  @include text1();

  color: $dark;
  display: grid;
  grid-template-areas: "name status plan-start plan-end required-amount percentage control";
  grid-template-columns: 2.4fr 1.2fr 0.8fr 0.8fr 0.8fr 0.8fr 52px;
  grid-column-gap: 12px;
  padding: 16px 24px;
  transition: background-color 250ms ease;

  @include hover {
    background-color: $blue-background-light;
  }
}

.stages-name-active,
.stages-name-disabled {
  font-weight: bold;
  grid-area: name;
  align-self: center;
  overflow: hidden;

  .input-field-text {
    text-overflow: ellipsis;
    overflow: hidden;
    color: $dark;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 23px;
    max-height: 46px;
  }
}

.stages-name-active {
  cursor: pointer;

  .input-field-text {
    @include underline;
    color: $bluer;
    transition: color 250ms ease, text-decoration-color 250ms ease;

    @include hover {
      color: $accent;
    }
  }
}

.stages-plan-start {
  grid-area: plan-start;
  align-self: center;
}

.stages-plan-end {
  grid-area: plan-end;
  align-self: center;
}

.stages-status {
  grid-area: status;
  align-self: center;
}

.stages-required-amount-block {
  grid-area: required-amount;
  align-self: center;
}

.stages-required-amount {
}

.stages-required-amount-block-items {
  @include flexGap(4px);
}

.red-stages-text {
  .input-field-text {
    color: $red;
    text-align: left;
  }
}

.stages-unit {
  grid-area: unit;
  align-self: center;
  .input-field-text {
    text-overflow: ellipsis;
    overflow: hidden;
    color: $dark;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 46px;
  }
}

.stages-percentage {
  grid-area: percentage;
  align-self: center;
}

.stages-control {
  display: flex;
  grid-area: control;
  align-self: center;

  > *:not(:last-child) {
    margin-right: 12px;
  }
}

.stages-control-delete,
.stages-control-cancel,
.stages-control-delete__disabled,
.stages-control-save,
.stages-control-edit {
  @include button-text();

  > svg {
    margin: 0 10px 0 0;
  }

  @include --mobile-lg {
    @include button-outline;
    flex: 1 1 auto;
  }
}

.mobile-label {
  display: none;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

@include --tablet {
  .__react_component_tooltip {
    display: none;
  }
  .stages-item {
    grid-template-areas:
      "name name name name name name plan-start plan-start plan-start plan-end plan-end plan-end"
      "status stutus stutus percentage percentage percentage required-amount required-amount required-amount required-amount required-amount control";
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 12px;
    padding: 12px 16px;

    .mobile-label {
      color: $grayest;
      display: block;
    }
  }

  .stages-required-amount-block-items {
    flex-wrap: nowrap;
  }
}
@include --mobile-lg {
  .stages-item {
    //grid-template-columns: 1fr 1fr !important;
    //grid-column-gap: unset !important;
    //grid-template-rows: unset !important;
    //grid-template-areas:
    //        "name name"
    //        "plan-start plan-end"
    //        "required-amount unit" !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px 16px;

    > * {
      align-self: flex-start;
      width: 100%;
    }
  }

  .stages-required-amount-block,
  .stages-percentage,
  .stages-plan-start,
  .stages-plan-end,
  .stages-status {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 12px;
  }

  .object-section-label-open {
    //margin-bottom: 24px !important;
    //position: sticky;
    //padding: 10px 0;
    //top: 0;
    //background-color: #fff;
  }
  //.stages-required-amount {
  //  grid-row-start: 3 !important;
  //  grid-row-end: 4 !important;
  //  grid-column-start: 1 !important;
  //  grid-column-end: 2 !important;
  //  margin-right: 4px;
  //  height: 100%;
  //}
  //.stages-unit {
  //  grid-row-start: 3 !important;
  //  grid-row-end: 4 !important;
  //  grid-column-start: 2 !important;
  //  grid-column-end: 3 !important;
  //  margin-left: 4px;
  //  height: 100%;
  //}
  //.stages-unit .input-field-text,
  //.stages-name-active .input-field-text,
  //.stages-name-disabled .input-field-text {
  //  max-height: unset !important;
  //  -webkit-line-clamp: unset !important;
  //}
  //.stages-percentage {
  //  grid-row-start: 4 !important;
  //  grid-row-end: 5 !important;
  //  grid-column-start: 1 !important;
  //  grid-column-end: 7 !important;
  //}
  //.stages-plan-start {
  //  grid-column-start: 1 !important;
  //  grid-column-end: 2 !important;
  //  margin-right: 4px;
  //  height: 100%;
  //}
  //.stages-plan-end {
  //  grid-column-start: 2 !important;
  //  grid-column-end: 3 !important;
  //  margin-left: 4px;
  //  height: 100%;
  //}

  //.stages-control-1{
  //  grid-row-start: 6 !important;
  //  grid-row-end: 7 !important;
  //  grid-column-start: 1 !important;
  //  grid-column-end: 7 !important;
  //}
  //.stages-control-2 {
  //  grid-row-start: 5 !important;
  //  grid-row-end: 6 !important;
  //  grid-column-start: 1 !important;
  //  grid-column-end: 7 !important;
  //}
  //.stages-control-1,
  //.stages-control-2 {
  //  display: none;
  //}
}

//@media all and (max-width: 475px) {
//  .stages-item {
//    display: flex;
//    flex-direction: column;
//  }
//  .stages-plan-start,
//  .stages-name-active,
//  .stages-name-disabled,
//  .stages-plan-end,
//  .stages-required-amount,
//  .stages-unit,
//  .stages-percentage,
//  .stages-control-1,
//  .stages-control-2,
//  .stages-control-delete,
//  .stages-control-cancel
//  {
//    width: 100%;
//  }
//}
