@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.objects-map-container {
  border-radius: 8px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.075);
  overflow: hidden;

  .map-map {
    height: 480px;
    width: 100%;
  }
}

.object-map-popup-show {
  max-width: 200px;
  padding: 12px;
  border: 1px solid $grayer;
  border-radius: 8px;
  background-color: $white;
  color: $dark;
  z-index: 9999;
}
