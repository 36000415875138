@import "src/assets/scss/mixins";

.loader-container {
  position: absolute;
  z-index: 1001;
  top: calc(50% - 9px);
  right: calc(50% - 36px);
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @include text1(bold);
}
@keyframes rotate{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
