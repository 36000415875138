@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

@media all and (max-width: 980px) {
  .solo-object-map {
    min-height: unset !important;
  }
}

.solo-object-customer,
.solo-object-contractor,
.solo-object-links {
  padding: 12px 24px;
  border-top: 1px solid $stroke;
}

.solo-object-links {
  padding: 0 24px;
  .objects-search {
    height: unset !important;
  }
}

.solo-object-files {
  padding-right: 24px;
  padding-left: 24px;
  border-top: 1px solid $grayer;
}

.solo-object-estimates-add-contractor {
  @include button-filled;
}

@include --tablet {
  .contractor-info-executors-content {
    grid-template-columns: 1fr 1fr !important;
    margin-bottom: 24px;
  }
  .contractor-info-builder-contact {
    margin-bottom: 12px;
  }
  .object-info {
    grid-template-areas: unset;
  }
  .solo-object-controls {
    grid-row-start: 5 !important;
    grid-column-start: 1 !important;
  }
}

@include --mobile-lg {
  .contractor-info-executors-content {
    display: flex !important;
    flex-direction: column;
    margin-bottom: 12px;
    &-contacts {
      margin-bottom: 12px;
    }
    &-delete, &-save {
      margin-bottom: 12px;
    }
  }
}
