@font-face {
  font-family: "PT Astra Sans";
  src: url("Ptastrasans.woff2") format("woff2"),
    url("Ptastrasans.woff") format("woff"),
    url("Ptastrasans.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PT Astra Sans";
  src: url("Ptastrasansbold.woff2") format("woff2"),
    url("Ptastrasansbold.woff") format("woff"),
    url("Ptastrasansbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
