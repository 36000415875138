@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.notification-card {
  @include text1;
  padding: 0 32px;
  background-color: $blue-background-light;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  @include --tablet {
    padding: 0 24px;
  }

  @include --mobile-lg {
    padding: 0 20px;
  }

  &._read {
    background-color: $white;
  }

  &__content {
    position: relative;
    padding: 12px 0 16px 24px;
    border-bottom: 1px solid $stroke;

    @include --mobile-lg {
      padding: 12px 0 16px;
    }

    &::before {
      position: absolute;
      top: 16px;
      left: 0;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $accent;
      content: "";

      ._read & {
        content: none;
      }

      @include --mobile-lg {
        top: 15px;
        width: 8px;
        height: 8px;
      }
    }
  }

  &__date {
    @include text2;
    color: $grayest;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    @include --mobile-lg {
      padding-left: 20px;
    }
  }
  &__title {
    @include header4();

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__controls {
    @include flexGap();
    justify-content: flex-end;

    @include --mobile-lg {
      @include flexGap(12px);

      > * {
        flex-basis: 100%;
      }
    }
  }

  &__read-btn {
    @include button-outline;
    background-color: transparent;
  }

  &__target-btn {
    @include button-filled;
  }
}
