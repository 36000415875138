@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.stage-images-container {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.stage-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, 72px);
  grid-template-rows: 70px 70px;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  list-style: none;
  min-height: 148px;
  border-radius: 5px;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.stage-images-empty {
  @include text1();
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: $grayest;
}

.stage-images-item {
  width: calc(100% - 10px);
  background-color: $dark;
  cursor: pointer;
  padding: 5px;

  img {
    //height: 52px;
    width: 100%;
  }
}

.stage-images-controls {
  display: flex;

  @include --mobile-lg {
    flex-direction: column;
  }
}

.stage-images-paginator {
  margin-left: auto;
  max-width: 280px;
}

.stage-images-add {
  @include button-filled();

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  input {
    display: none;
  }
}
