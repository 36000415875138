@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.rating-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include --mobile-lg {
    > * {
      flex: 1 1 100%;
    }
  }

  &:not(._editable) {
    padding: 12px 24px;
    border: 1px solid $grayer;
    border-radius: 4px;

    @include --mobile-lg {
      padding: 12px;
    }
  }

  &__head {
    flex: 1 1 auto;

    &:not(:last-child) {
      margin-right: 12px;

      @include --mobile-lg {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }

  &__title {
    @include text1(bold);

    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }

  &__subtitle {
    @include text2;
    color: $grayest;

    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }

  &__list {
    @include reset-list;
    display: flex;
    flex: 0 0 auto;
  }

  &__star {
    ._editable & {
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-right: 5px;
    }

    > svg {
      width: 16px;
      height: 15px;
      fill: rgba($blue, 0);
      transition: fill 250ms ease, stroke 250ms ease;

      ._filled & {
        fill: $blue;
      }

      ._editable &:hover {
        fill: rgba($blue, 0.3);
      }
    }
  }

  &__review {
    @include custom-scrollbar;
    flex: 1 1 100%;
    max-width: 100%;
    font-size: 12px;
    line-height: 14px;
    background-color: $white;

    ._editable & {
      padding: 12px;
      border: 1px solid $grayer;
      border-radius: 3px;
      resize: none;
      outline: none;
    }

    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}
