@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.confirm {
  &-header {
    @include header1();

    color: $dark;
    margin-bottom: 24px;
  }

  &-card {
    &:not(:last-child) {
      margin-bottom: 12px;

      @include --mobile-lg {
        margin-bottom: 0;
      }
    }
  }
}