@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.tutorial {
  box-sizing: border-box;
  width: 546px;
  //max-width: 100%;
  padding: 24px;
  position: fixed;
  border: 1px solid #dbdad6;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2), 0 10px 14px rgba(0, 0, 0, 0.14),
    0 4px 18px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  background-color: $white;

  @include --mobile-lg {
    width: 280px;
    padding: 16px;
  }

  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 100%;
  }

  &-title {
    @include header4();
    margin: 0 0 8px;
  }

  &-content {
    @include text1();
    color: $grayest;
    margin-bottom: 24px;

    p {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &-example {
    border: 1px solid $stroke;
    border-radius: 4px;

    &.-statuses {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 24px;
      gap: 16px;
    }
  }

  &-footer {
    display: grid;
    grid-template-areas: "nth controls";
    grid-template-columns: 1fr auto;
  }

  &-nth {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #aaa9a5;
  }

  &-controls {
    grid-area: controls;
    display: flex;
    gap: 24px;
  }

  &-skip,
  &-next {
    @include button-text();
  }

  &-skip {
    color: $grayest;
    border-color: $grayest;

    @include hover {
      background-color: $grayest;
    }
  }

  &-tip {
    position: absolute;

    &.-top {
      top: -41px;
      transform: translateX(34px);
    }

    &.-left {
      top: 0;
      left: 0;
      transform: rotate(-90deg);

      &-center {
        top: calc(50% - 15px);
      }
    }

    &.-left-center {
      top: calc(50% - 15px);
      left: 0;
      transform: rotate(-90deg);
    }
  }
}
