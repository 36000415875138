@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.solo-object-head {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.solo-object-head-name {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .input-label {
    display: flex;
    align-items: center;
  }

  .input-label-container {
    width: 100%;
  }

  .input-field-label {
    display: none;
  }

  .input-field-text {
    @include header2();
    padding-bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .input-input {
    @include header2();
    color: $dark;
  }
}

.solo-object-head-switch {
  border-bottom: 1px solid $stroke;

  @include --mobile-lg {
    border-bottom: none;
  }

  &:not(:last-child) {
    margin-bottom: 32px;

    @include --mobile-lg {
      margin-bottom: 24px;
    }
  }
}

.solo-object-head-controls {
  @include flexGap();

  @include --mobile-lg {
    @include flexGap(12px);

    > * {
      flex-grow: 1;
    }
  }
}

.solo-object-head-change {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  order: 3;
}

.solo-object-head-set-status,
.solo-object-head-freeze,
.solo-object-head-respond,
.solo-object-head-rating,
.solo-object-add-contractor {
  @include button-filled;
}

.solo-object-head-delete,
.solo-object-head-summary,
.solo-object-head-edit {
  @include button-outline;
}

//.solo-object-head-edit,
//.solo-object-head-edit-disabled,
//.solo-object-head-edit-cancel, {
//  order: 5;
//  border: none;
//  background-color: $white;
//  cursor: pointer;
//  display: flex;
//  justify-content: center;
//
//  &:not(:last-child) {
//    margin-right: 12px;
//  }
//
//  &:focus {
//    outline: none;
//  }
//
//  svg {
//    fill: $blue;
//  }
//}
//
//.solo-object-head-rating {
//  display: inline-flex;
//  flex: 0 1 auto;
//  margin-left: -3px;
//}

//.solo-object-head-edit-cancel {
//  svg {
//    fill: $red;
//  }
//}
//
//.solo-object-head-freeze,
//.solo-object-head-rating,
//.solo-object-head-add-contractor,
//.solo-object-head-delete {
//  @include button-filled();
//
//  order: 4;
//
//  &:not(:last-child) {
//    margin-right: 12px;
//  }
//}
//
//.solo-object-head-delete {
//  flex: 0 0 auto;
//  width: auto;
//  padding: 0 12px;
//}
//
//.solo-object-head-summary{
//  margin-right: 12px;
//  order: 5;
//  border: none;
//  background-color: $white;
//  display: flex;
//  align-items: center;
//  cursor: pointer;
//
//  &:focus{
//    outline: none;
//  }
//
//  svg{
//    width: 20px;
//    height: 20px;
//    fill: $blue;
//  }
//}
//
//.solo-object-head-edit-disabled {
//  cursor: not-allowed;
//
//  svg {
//    fill: $grayest;
//  }
//}

//@media all and (max-width: 850px) {
//  .solo-object-head {
//    flex-wrap: wrap;
//  }
//
//  .solo-object-head-name {
//    width: 100%;
//    margin-bottom: 12px;
//    margin-right: 0;
//    font-size: 18px;
//  }
//
//  .solo-object-head-switch {
//    margin-right: auto;
//    margin-left: auto;
//  }
//  .solo-object-head-switch {
//    order: 5;
//    padding-right: 0;
//    margin-top: 12px;
//  }
//  .solo-object-head-freeze {
//    margin-right: 0 !important;
//  }
//}
//
//@media all and (max-width: 600px) {
//  .solo-object-head-change {
//    margin-left: 0;
//    margin-bottom: 12px;
//  }
//
//  .solo-object-head-status {
//    margin-left: auto;
//    margin-bottom: 12px;
//    margin-right: 0;
//  }
//  .solo-object-head {
//    justify-content: center;
//  }
//}
//@media all and (max-width: 480px) {
//  .solo-object-head-name {
//    .input-field-text {
//      font-size: 16px !important;
//    }
//      input {
//      font-size: 16px !important;
//    }
//  }
//}
