@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.tabs {
  border: 1px solid $stroke;
  border-radius: 4px;
  background: $white;
  //width: calc(100% - 6px);
  //height: 25px;
  //padding: 2px;
  display: flex;

  &._nav-tab {
    border: none;
    border-radius: 0;
  }
}

.tabs-item {
  @include text1(bold);

  width: 100%;
  padding: 8px 12px;
  color: $grayest;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

  &:not(:last-child) {
    border-right: 1px solid $stroke;

    ._nav-tab & {
      border-right: none;
      margin-right: 32px;
    }
  }

  ._nav-tab & {
    width: auto;
    padding: 16px 1px 12px;
    border-bottom: 4px solid transparent;
    background-color: transparent;
  }

  &:hover {
    text-decoration: none;
    color: $bluest;
  }
}

.tabs-item:focus {
  outline: none;
}

.tabs-item-active {
  background: $blue-background-light;
  color: $accent;

  ._nav-tab & {
    border-bottom-color: $accent;
  }
}

.tabs-select {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $white;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &__options {
    @include reset-list;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-bottom: 4px solid $accent;
    background-color: $white;
  }

  &__item {
    @include reset-btn;
    @include header4();
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid $stroke;
    color: $dark;
    background-color: $white;
    cursor: pointer;
    transition: color 250ms ease, background-color 250ms ease, border 250ms ease;

    @include hover {
      color: $accent;
      background-color: $blue-background-light;
    }

    @include --mobile-lg {
      padding: 9px 0;
    }

    &._active {
      position: relative;
      color: $accent;

      &::before {
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        width: 100%;
        height: 4px;
        background-color: $accent;
        content: "";
      }
    }

    &._toggle {
      &::after {
        display: block;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23184870' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: transform 250ms ease;
        content: "";
      }

      &:not(._active)::after {
        transform: rotate(90deg);
      }
    }
  }
}
