@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.estimate-card-wrapper,
.estimate-card-head {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  align-items: center;
  width: 100%;
  transition: background-color 250ms ease;

  @include hover {
    //background-color: $blue-background-light;
  }

  @include --tablet {
    grid-column-gap: 16px;
  }

  @include --mobile-lg {
    grid-template-columns: 1fr;
  }
}

._estimate .file-list-file-card,
.estimate-card-head {
  padding: 8px 0;

  @include --mobile-lg {
    margin: 0 -20px;
    padding: 16px 20px;
    border-bottom: 1px solid $stroke;

    &:first-child {
      border-top: 1px solid $stroke;
    }
  }
}

.estimate-card-block {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 4px;
  }
}

.estimate-card-label {
  @include text1();
  display: block;
  color: $grayest;
}

.estimate-card-head {
  @include header4();
  color: $accent;
}

.file-card,
.file-card-selected {
  display: flex;
  border: 1px solid $stroke;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 250ms ease;

  @include hover {
    background-color: $blue-background-light;
  }

  ._estimate & {
    //border: none;
  }
}

.file-card-selected {
  background-color: $gray-background;
}

.file-card-icon {
  min-width: 44px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 26px;
    height: 34px;
  }
}

.file-card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-right: 12px;
  padding-left: 6px;
}

.file-card-name-form {
  display: flex;
}

.file-card-name-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.file-card-name {
  @include text1();
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: calc(100% - 36px);
  border: none;
  color: $accent;
  background-color: transparent;
  pointer-events: none;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &:active,
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: 0;
  }
}

.file-card-change,
.file-card-remove,
.file-card-name-update {
  @include button-text;
}

.file-card-size {
  @include text2();
  color: $grayest;
}

.file-card-person-block {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 9px 52px 9px 12px;
  border: 1px solid $grayer;
  border-radius: 5px;

  .file-card-change,
  .file-card-remove {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }

  .file-card-remove {
    right: 40px;
  }
}

.file-card-person-name {
  @include text1;
  @include reset-link;
  display: block;
  flex-basis: 100%;
  max-width: 100%;
  border-radius: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color 250ms ease;

  &._empty {
    cursor: auto;
  }

  @include hover {
    color: $bluer;
  }

  @include --tablet {
    max-width: 150px;
  }

  @include --mobile-lg {
    max-width: none;
  }
}

.file-card-select {
  justify-self: end;
}

.file-card-person-title {
  @include text1(bold);
  display: inline-block;
  margin-bottom: 4px;
}

@include --mobile-lg {
  .estimate-card-wrapper {
    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
  .file-list-container {
    flex-direction: column-reverse;
  }

  .file-card-person-block {
    max-width: 100%;
  }

  .file-card-person-title {
    margin-bottom: 6px;
  }
}
