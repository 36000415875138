@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.paginator {
  display: grid;
  //grid-template-areas: "back-all back current forward forward-all size";
  grid-template-areas: "back-all back current forward forward-all";
  //grid-template-columns: 25px 25px 90px 25px 25px 60px;
  grid-template-columns: min-content min-content max-content min-content min-content;
  grid-template-rows: 25px;
  grid-column-gap: 4px;
}

.paginator-back-all {
  grid-area: back-all;
}

.paginator-back {
  grid-area: back;
}

.paginator-pages {
  grid-area: current;
  display: flex;
  //display: grid;
  //grid-template-columns: min-content min-content;
  //grid-column-gap: 6px;

  > * {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}

.paginator-pages-current {
  @include text1();

  border: 1px solid $grayer;
  border-radius: 4px;
  width: 58px;
  padding: 0 6px 0 6px;
  color: $dark;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
    border-color: $blue;
  }
}

.paginator-pages-total {
  @include text1();

  color: $dark;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginator-forward {
  grid-area: forward;
}

.paginator-forward-all {
  grid-area: forward-all;
}

.paginator-back-all,
.paginator-back,
.paginator-forward,
.paginator-forward-all {
  background-color: $white;
  font-weight: bold;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 25px;
  transition: background-color 250ms ease;

  @include hover {
    background-color: $grayer;
  }

  &:focus {
    outline: none;
  }
}

.paginator-sizes {
  grid-area: size;

  .select-input {
    height: 24px;
  }
}
