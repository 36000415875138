@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.add-contractor {

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 50px 50px;
  grid-column-gap: 12px;

  &-save {
    @include button-filled();
    align-self: flex-end;
    height: 30px;
    svg {
      width: 15px;
      height: 15px;
      fill: $white;
    }
  }
  &-cancel {
    @include button-outline();
    height: 30px;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: $red;
    color: $red;

    svg {
      fill: $red;
      width: 12px;
      height: 12px;
    }

    &:hover {
      background-color: $red;

      svg {
        fill: $white;
      }
    }
  }
}
