@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.customer-info {
  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr;
  //grid-gap: 16px;
  //
  //@include --tablet {
  //  display: flex;
  //  flex-direction: column;
  //}
}

.customer-info-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr;
  grid-gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;

    &._org {
      padding-bottom: 16px;
      border-bottom: 1px solid $stroke;
    }
  }

  @include --tablet {
    display: flex;
    flex-direction: column;
  }
}

.customer-info-name {
  grid-row: 1 / 2;
  grid-column: 1 / 4;
  max-width: 328px;
}

.customer-info-contact {
  grid-row: 2 / 3;
}

.customer-info-email {
  grid-row: 2 / 3;
}

.customer-info-additional {
  grid-row: 2 / 3;
  grid-column: 3 / 6;
}

.customer-info-controls {
  @include flexGap(16px);
  justify-content: flex-end;

  @include --mobile-lg {
    > * {
      flex: 1 1 auto;
    }
  }
}

.customer-info-cancel {
  @include button-outline();
  grid-row: 3 / 4;
  grid-column: 4 / 5;
}

.customer-info-save {
  @include button-filled();
  grid-row: 3 / 4;
  grid-column: 5 / 6;
}
