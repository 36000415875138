@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.create-user-block {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 16px;

  @include --tablet {
    grid-template-columns: 1fr 1fr;
  }

  @include --mobile-lg {
    grid-template-columns: 1fr;
  }

  .input-field {
    padding: 0;
  }

  &__title {
    @include header3();
    grid-column: 1 / -1;
  }

  &__item {
    &._info {
      grid-column: 1 / -1;
    }
  }

  &__controls {
    grid-column: 1 / -1;
    display: flex;
    justify-content: flex-end;

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__add-btn,
  &__create-btn {
    @include button-filled();
  }

  &__add-btn {
    grid-column: 1 / -1;
    justify-self: flex-end;

    @include --mobile-lg {
      width: 100%;
    }
  }

  &__cancel-btn {
    @include button-outline();
  }

  &__add-btn,
  &__create-btn,
  &__cancel-btn {
    flex: 0 1 auto;

    @include --mobile-lg {
      flex-grow: 1;
      min-width: auto;
    }
  }
}
