@import "src/assets/scss/colors";

.switch,
.switch-active {
  position: relative;
  width: 55px;
  height: 24px;
  border-radius: 100px;
  background-color: $grayest;
  cursor: pointer;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &::before {
    position: absolute;
    top: 2px;
    display: block;
    width: 20px;
    height: 20px;
    color: $white;
  }
}

.switch {
  &::before {
    right: 8px;
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.3334 5.34169L15.1584 4.16669L10.5001 8.82502L5.84175 4.16669L4.66675 5.34169L9.32508 10L4.66675 14.6584L5.84175 15.8334L10.5001 11.175L15.1584 15.8334L16.3334 14.6584L11.6751 10L16.3334 5.34169Z' fill='white'/%3E%3C/svg%3E%0A");
  }
}

.switch-active {
  background-color: $blue;

  &::before {
    left: 8px;
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.0001 5.83333L8.00008 15.8333L3.41675 11.25L4.59175 10.075L8.00008 13.475L16.8251 4.65833L18.0001 5.83333Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .switch-slider {
    right: 2px;
    left: auto;
  }
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 20px;
  width: 20px;
  background-color: $white;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
