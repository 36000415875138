@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.add-tasks {
  display: grid;
  grid-template-columns: repeat(6, 0.8fr) repeat(6, 1.2fr);
  grid-column-gap: 12px;
  grid-row-gap: 16px;
  align-items: flex-end;

  &__name {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  &__plan-beg {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 5;
  }
  &__plan-end {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 5;
    grid-column-end: 9;
  }
  &__amount {
    grid-column-start: 9;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  &__unit {
    grid-column-start: 11;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  &__controls {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 9;
    grid-column-end: 13;
    display: flex;
    justify-content: flex-start;

    &._not-editing {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 6;
    }

    > * {
      @include --mobile-lg {
        flex: 1 0 auto;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    &__save,
    &__add{
      @include button-filled();
    }

    &__save{

    }

    &__cancel{
      @include button-outline;
    }
  }
}

@include --mobile-lg {
  .add-tasks {
    display: flex;
    flex-direction: column;
    &__name, &__plan-beg, &__plan-end, &__amount, &__unit, &__controls {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
