@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.confirm-action {
  max-width: 300px;

  &__text {
    @include text1;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__cancel {
    @include button-outline;
  }

  &__confirm {
    @include button-filled;
  }

  &__cancel,
  &__confirm {
    flex: 1 0 auto;
  }
}
