@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.rating-block {
  width: 507px;
  max-width: 100%;

  &__head {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @include --mobile-lg {

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__title {
    @include header3();
    flex: 1 1 auto;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__rating {
    flex: 0 0 auto;
  }

  &__list {
    @include custom-scrollbar();
    @include reset-list;
    display: flex;
    flex-direction: column;
    max-height: 385px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    > li {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    @include button-outline;
    width: auto;

    &:not(:last-child) {
      margin-right: 24px;
    }

    &._gray {
      color: $grayest;
      border-color: $grayest;

      @include hover {
        color: $white;
        background-color: $grayer;
      }

      @include active {
        background-color: $grayest;
      }
    }
  }

  &__errors {
    color: $red;
  }
}
