@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.contractor-info {
  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
  //grid-gap: 16px;
}

.contractor-info-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
  //grid-template-areas:
  //  "org-name org-contacts org-email cancel save"
  //  "builder-name builder-contacts builder-email cancel save";
  grid-gap: 16px;

  @include --tablet {
    display: flex;
    flex-direction: column;
  }

  &:not(:last-child) {
    margin-bottom: 16px;

    &._org {
      padding-bottom: 16px;
      border-bottom: 1px solid $stroke;
    }
  }
}

.contractor-info-block-title {
  @include header4;
  grid-column: 1 / 7;
}

.contractor-info-org-name {
  //grid-area: org-name;
}

.contractor-info-org-contacts {
  //grid-area: org-contacts;
}

.contractor-info-org-address {
  grid-column: 3 / 7;
}

.contractor-info-builder-name {
  grid-column-start: 1;
}

.contractor-info-builder-contacts {
  //grid-area: builder-contacts;
}

.contractor-info-builder-email {
  //grid-area: builder-email;
}

.contractor-info-builder-additional {
  grid-column-start: 4;
  grid-column-end: 7;
}

.contractor-info-builder-add {
  @include button-filled();

  //grid-area: save;
  //grid-row-start: 3;
  //grid-row-end: 4;
  grid-column-start: 4;
  grid-column-end: 5;

}

.contractor-info-executors-content {
  grid-column-start: 1;
  grid-column-end: 7;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr 50px 50px;

  &-edit, &-save {
    @include button-filled();
    align-self: flex-end;
  }

  &-delete, &-cancel {
    @include button-outline();
    align-self: flex-end;
  }
}

.add-contractor {
  //grid-row-start: 3;
  //grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 7;
}

.contractor-info-builder-controls {
  @include flexGap(16px);
  justify-content: flex-end;

  @include --mobile-lg {
    > * {
      flex: 1 1 auto;
    }
  }
}

.contractor-info-builder-cancel {
  @include button-outline();
}

.contractor-info-builder-save {
  @include button-filled();
}
