@import "../../../../assets/scss/colors";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/breakpoints";

.stages-container {
  padding: 0 24px 24px 24px;
  min-height: 821px;
}

@include --tablet {
  .stages-container {
    min-height: unset !important;
  }
}

.stages-sections {
  margin-bottom: 12px;
}

.section-paginator {
  margin: 32px auto 0;
  width: fit-content;
}

.stages-section {
  min-height: unset !important;
  display: flex;
  align-items: center;
}

.stages-controls {
  display: flex;
  justify-content: flex-end;
}

.stages-controls-cancel {
  @include button-outline();
}

.stages-controls-save {
  @include button-filled();
}

.section-create {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include --mobile-lg {
    flex-wrap: wrap;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  > * {
    @include --mobile-lg {
      flex: 1 0 100%;
    }

    &:not(:last-child) {
      margin-right: 16px;

      @include --mobile-lg {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  button:not([class]) {
    @include button-filled();
  }

  .cancel {
    @include button-outline();
  }
}

.section-create-controls {
  display: flex;

  > * {
    flex: 1 0 auto;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
