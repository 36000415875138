@import "../../../../../../../../../../assets/scss/mixins";
@import "../../../../../../../../../../assets/scss/colors";
@import "../../../../../../../../../../assets/scss/breakpoints";

.add-object-stage-container {
  //padding-right: 12px;
  //padding-left: 12px;
  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }

  @include --mobile-lg {
    border-bottom: 1px solid $stroke;

    &:first-of-type {
      border-top: 1px solid $stroke;
    }
  }
}

.add-object-stage {
  display: grid;
  grid-template-areas: "name plan-beg plan-end amount unit controls";
  grid-template-columns: 2fr 0.8fr 0.8fr 0.8fr 0.8fr 52px;
  grid-column-gap: 12px;
  padding: 16px 24px;
  list-style: none;
  color: $accent;
  text-decoration: none;
  transition: background-color 250ms ease;

  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }

  @include hover {
    background-color: $blue-background-light;
  }

  @include --tablet {
    grid-template-areas:
      "name name name name name name name name empty empty controls controls"
      "plan-beg plan-beg plan-beg plan-end plan-end plan-end amount amount amount unit unit unit";
    grid-template-columns: repeat(10, 1fr) 52px;
    grid-row-gap: 12px;
    padding: 12px 16px;
  }

  @include  --mobile-lg {
    display: flex;
    flex-direction: column;
  }
}

.add-object-stage-name {
  grid-area: name;
  overflow: hidden;
  cursor: pointer;

  .input-field-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.add-object-stage-plan-beg {
  grid-area: plan-beg;
}

.add-object-stage-plan-end {
  grid-area: plan-end;
}

.red-text {
  .input-field-text {
    color: $red;
  }
}

.add-object-stage-amount {
  grid-area: amount;
}

.add-object-stage-unit {
  grid-area: unit;
  .input-field-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 80px;
  }
}

.add-object-stage-controls {
  grid-area: controls;
  display: flex;

  > * {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  @include --mobile-lg {
    justify-content: flex-end;
  }
}

.add-object-stage-control-1-save,
.add-object-stage-control-1-delete {
  grid-area: control-1;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-object-stage-control-2-cancel,
.add-object-stage-control-2-edit {
  grid-area: control-2;
  align-self: center;
  justify-self: center;
}

.add-object-stage-control-2-edit,
.add-object-stage-control-1-save,
.add-object-stage-control-1-delete,
.add-object-stage-control-2-cancel {
  @include button-text();

  > svg {
    margin: 0 10px 0 0;
  }
}
