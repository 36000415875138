@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.add-object-container {
}
.Toaster__alert {
  padding: 0 !important;
}
.Toaster__alert_close {
  top: 0 !important;
}
.Toaster__alert_close {
  box-shadow: none !important;
}
.error-toast {
  background-color: $red-background-light !important;
  color: $red;
  padding: 12px 48px 12px 12px;
}
.success-toast {
  background-color: #CCE7E1 !important;
  color: #2e8d77;
  padding: 12px 48px 12px 12px;
}
.add-object {
}
