@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.archive-container{
  max-width: 1112px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 12px;
  padding-left: 12px;
}

.archive-header{
  @include header1();

  color: $dark;
  margin-bottom: 24px;
}

.archive-list-container {
  border: 1px solid $stroke;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  @include --mobile-lg {
    border: none;
  }
}

.archive-list-header{
  @include header4();

  color: $accent;
  padding: 16px 24px;
  display: grid;
  grid-template-areas: "name plan-end percentage status subscribe delete";
  grid-template-columns: 3fr 1.2fr 1fr 1.2fr min-content min-content;
  grid-column-gap: 24px;
  list-style: none;
  background-color: $gray-background;

  &:not(:last-child) {
    grid-template-areas: "name plan-end percentage status delete";
    grid-template-columns: 3fr 1.2fr 1fr 1.2fr min-content;
    padding: 12px 16px;
    border-bottom: 1px solid $stroke;
  }

  @include --tablet {
    grid-column-gap: 12px;
  }

  @include --mobile-lg {
    display: none;
  }
}

.archive-list-header-subscribe {
  justify-self: center;
  grid-area: subscribe;
  width: 120px;
  text-align: center;
}

.archive-list-header-delete {
  grid-area: delete;
  width: 20px;
}

.archive-list-header-name{
  grid-area: name;
  width: 100%;
}

.archive-list-header-end{
  grid-area: plan-end;
  justify-self: flex-start;
  width: 100%;
}

.archive-list-header-contractor{
  justify-self: center;
  grid-area: contractor;
  width: 100%;
}

.archive-list-header-complete{
  justify-self: flex-end;
  grid-area: percentage;
  width: 100%;
}

.archive-list-header-status{
  justify-self: flex-end;
  grid-area: status;
  width: 100%;
}

.archive-list{
  list-style: none;
}

.archive-list-item{
  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }

  @include --mobile-lg {
    margin: 0 -20px;
    border-bottom: 1px solid $stroke;

    &:first-child {
      border-top: 1px solid $stroke;
    }
  }
}
