$desktop: 1171px; // from
$tablet: 1023px;
$tablet_v: 1023px;
$mobile_lg: 767px;
$mobile_md: 480px;
$mobile_sm: 360px;

$from_tablet: #{$tablet + 1px};
$from_tablet_v: #{$tablet_v + 1px};
$from_mobile_lg: #{$mobile_lg + 1px};
$from_mobile_md: #{$mobile_md + 1px};
$from_mobile_sm: #{$mobile_sm + 1px};

@mixin --desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin --tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin --tablet-v {
  @media (max-width: $tablet_v) {
    @content;
  }
}

@mixin --mobile-lg {
  @media (max-width: $mobile_lg) {
    @content;
  }
}

@mixin --mobile-md {
  @media (max-width: $mobile_md) {
    @content;
  }
}

@mixin --mobile-sm {
  @media (max-width: $mobile_sm) {
    @content;
  }
}

// from
@mixin --from-tablet {
  @media (min-width: $from_tablet) {
    @content;
  }
}

@mixin --from-tablet-v {
  @media (min-width: $from_tablet_v) {
    @content;
  }
}

@mixin --from-mobile-lg {
  @media (min-width: $from_mobile_lg) {
    @content;
  }
}

@mixin --from-mobile-md {
  @media (min-width: $from_mobile_md) {
    @content;
  }
}

@mixin --from-mobile-sm {
  @media (min-width: $from_mobile_sm) {
    @content;
  }
}

// only
@mixin --only-tablet {
  @media (min-width: $from_mobile_lg) and (max-width: $tablet) {
    @content;
  }
}
