@import "src/assets/scss/mixins";
@import "src/assets/scss/colors";

.rating-stars {
  display: inline-flex;
  align-items: center;

  &._clickable {
    cursor: pointer;
  }

  &__list {
    @include reset-list;
    display: flex;
    margin-right: 10px;

    > li {
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  &__value {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $grayest;
  }
}

.rating-value {
  color: $blue;
}
