@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.login-form {
  border: 0.5px solid $grayer;
  border-radius: 3px;
  padding: 48px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.075);
  display: flex;
  flex-direction: column;
  background: #fff;
}

.login-label {
  width: 100%;
  max-width: 263px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.login-form-reset {
  color: $dark;
  display: block;
  width: 100%;
  max-width: 263px;
  margin: 0 auto 12px;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-decoration: none;

  &:hover {
    opacity: 0.85;
  }
}

.login-label:last-of-type {
  margin-bottom: 24px;
}

.login-label-text {
  margin-bottom: 4px;
  color: $grayest;
}

.login-input-container {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  max-width: 263px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.login-thanks-head {
  @include text1(bold);

  color: $grayest;
  margin-bottom: 12px;
  max-width: 382px;
}

.login-thanks-text {
  @include text2();

  color: $grayest;
  margin-bottom: 24px;
  max-width: 382px;
}

.login-submit-error {
  @include text2();

  max-width: 263px;
  margin-left: auto;
  margin-right: auto;
  color: $red;
  margin-bottom: 6px;
}
.login-input-submit {
  @include button-filled();

  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  width: 100%;
  max-width: 150px;
}

.login-submit-error:last-of-type {
  margin-bottom: 12px;
}

.agree-label {
  cursor: pointer;
  font-size: 12px;
  width: 260px;
  margin: 0 auto 18px auto;
  color: $grayest;
  a {
    text-decoration: none;
    color: $bluest;
  }
  a:hover {
    text-decoration: underline;
  }
}
