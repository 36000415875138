@import "../../../../../../../../../../assets/scss/colors";
@import "../../../../../../../../../../assets/scss/mixins";
@import "../../../../../../../../../../assets/scss/breakpoints";

.add-object-sections-list-item-header {
  max-width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  > * {
    //flex: 1 0 auto;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.add-object-section-content {
  display: flex;
  flex-direction: column;
  padding-left: 30px;

  @include --mobile-lg {
    padding-left: 0;
  }
}

@media all and (max-width: 980px) {
  .add-object-sections-list-item-header {
    min-height: unset !important;
  }
}

.add-object-sections-list-name {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.add-object-section-list {
  border: 1px solid $stroke;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 24px;

    @include --mobile-lg {
      margin-bottom: 16px;
    }
  }

  @include --mobile-lg {
    margin: 0 -20px 0 -12px;
    border: none;
  }

  .mobile-label {
    @include text1();
    display: none;
    color: $grayest;

    @include --tablet {
      display: block;
    }
  }
}

.add-object-section-head {
  @include header4();
  display: grid;
  align-items: end;
  grid-template-areas: "name begin end amount unit empty";
  grid-template-columns: 2fr 0.8fr 0.8fr 0.8fr 0.8fr 52px;
  grid-column-gap: 12px;
  padding: 16px 24px;
  list-style: none;
  color: $accent;
  text-decoration: none;
  background-color: $gray-background;

  @include --tablet {
    display: none;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $stroke;
  }
}
.add-object-section-head-name{
  grid-area: name;
}

.add-object-section-head-begin{
  grid-area: begin;
}

.add-object-section-head-end{
  grid-area: end;
}

.add-object-section-head-amount{
  grid-area: amount;
}

.add-object-section-head-unit{
  grid-area: unit;
}


.add-object-sections-list-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 250ms;
  height: 12px;
  width: 12px;
  min-height: 18px;
  min-width: 18px;

  svg {
    width: 12px;
    height: 7px;
    fill: $blue;
  }

  &._open {
    transform: rotate(180deg);
  }
}

.add-stages-paginator-total {
  @include --mobile-lg {
    align-self: flex-end;
    margin-bottom: 8px;
  }
}

.add-stages-paginator {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include --mobile-lg {
    flex-direction: column;
    padding-right: 0;

    .show-all {
      margin-top: 12px;
    }
  }
}

.show-all,
.hide-all {
  @include button-outline;

  @include --mobile-lg {
    flex: 1 0 100%;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.hide-all {
  margin-left: auto;
}

@include --mobile-lg {
  .add-object-stage {
    display: flex !important;
    flex-direction: column;
  }
  .add-object-stage-plan-beg,
  .add-object-stage-name,
  .add-object-stage-plan-end,
  .add-object-stage-amount,
  .add-object-stage-unit,
  //.add-object-stage-control-1-delete,
  //.add-object-stage-control-2-edit,
  //.add-object-stage-control-2-cancel,
  //.add-object-stage-control-1-save
  {
    width: 100%;
  }

  .add-object-stage-plan-beg,
  .add-object-stage-plan-end,
  .add-object-stage-amount,
  .add-object-stage-unit {
    display: grid;
    grid-template-columns: 1.2fr 2fr;
  }
}
